const { Typography } = require("@mui/material");



function ErrorMessage(props) {
  const { message } = props;
  return (
    <Typography fontSize={12} color="darkred">
      {message}
    </Typography>
  );
}

export default ErrorMessage;
