import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = [
    {
        id: number;
        type: string;
        name: string;
        notes: string;
        latitude: number;
        longitude: number;
    }
]
type DeviceAction = {
    id: number;
    type: string;
    name: string;
    notes: string;
    latitude: number;
    longitude: number;
}

type DeleteAction = {
  // deleteDevice: (id: number) => void
  id: number
}

const initialState: InitialState = [
    {
      id: 0,
      type: "t1",
      name: "n1",
      notes: "done",
      latitude: 12.1231,
      longitude: 32.23232,
    },
  ];

  export const deviceSlice = createSlice({
    name: "device",
    initialState,
    reducers: {
      addDevice: (state, action: PayloadAction<DeviceAction>) => {
        state.push(action.payload);
      },
      deleteDevice: (state, action: PayloadAction<number>) => {
        const id = action.payload;
        if (id == null || id === undefined) {
          return;
        }
        const index = state.findIndex((d) => d.id === id);
        state.splice(index, 1);
      },
    },
  });
  
  export const { addDevice, deleteDevice } = deviceSlice.actions;
  
  export default deviceSlice.reducer;