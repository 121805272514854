import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SetOptions, SnapshotOptions } from "firebase/firestore";
import { Posts } from "../posts.type";

export class PostsConverter implements FirestoreDataConverter<Posts>{
    toFirestore(modelObject: any): DocumentData;
    toFirestore(modelObject: any, options: SetOptions): DocumentData;
    toFirestore(modelObject: Posts, options?: unknown): import("@firebase/firestore").DocumentData {
        return { ...modelObject };
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): Posts 
    {
        const data = snapshot.data();
        const posts: Posts = {
            id: snapshot.id,
            content: data.content,
            created_on: data.created_on,
            images: data.images,
            user: data.user,
            approval: {
                approved_by: data.approval,
                approved_on: data.approval
            }
        };

        return posts;
    }

}