import { getFirestore, collection, onSnapshot, getDocs } from 'firebase/firestore';
import { SubServiceConverter } from 'src/firebase/firestore/convertors/subServices.convertor';
import { SubService } from 'src/firebase/firestore/subService.type';
import { app } from '../../../firebase';

const firestore = getFirestore(app);
const subServicesCollection = collection(firestore, 'sub_services').withConverter(
  new SubServiceConverter()
);
export async function GetSubServices(): Promise<SubService[]> {

  if(!sessionStorage.getItem('SubServices')){
    const subservices = (await getDocs<SubService>(subServicesCollection)).docs.map(c=>c.data());
    sessionStorage.setItem('SubServices',JSON.stringify(subservices))
    return subservices;
  }
  const cacheSubservices: SubService[] = JSON.parse( sessionStorage.getItem('SubServices'));
  return cacheSubservices;
}
