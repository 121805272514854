import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SetOptions, SnapshotOptions } from "firebase/firestore";
import { AdsBanner } from "../adsBanner.type";

export class AdsBannerConverter implements FirestoreDataConverter<AdsBanner>{
    toFirestore(modelObject: any): DocumentData;
    toFirestore(modelObject: any, options: SetOptions): DocumentData;
    toFirestore(modelObject: AdsBanner, options?: unknown): import("@firebase/firestore").DocumentData {
        return { ...modelObject };
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): AdsBanner 
    {
        const fireStoreData = snapshot.data();
        const adsBanner: AdsBanner = {
            id: snapshot.id,
            enabled: fireStoreData.enabled,
            image: fireStoreData.image,
            text: fireStoreData.text,
            order: fireStoreData.order,
            data: fireStoreData?.data?{
                type: fireStoreData?.data?.type,
                user: fireStoreData?.data?.user,
                sub_service: fireStoreData?.data?.sub_service,
                url: fireStoreData?.data?.url,
            }: undefined
        };

        return adsBanner;
    }

}