import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import { useForm } from "react-hook-form";
import { useState } from 'react';
import ErrorMessage from "../../error";
import Button from "@mui/material/Button";
import axios, { AxiosError } from "axios";
import SuspenseLoader from "src/components/SuspenseLoader";
import { API_URL } from "src/constants";

type handleClose = {
    hanldeClose: () => void;
    id: string;
    closeEditMenu: () => void;
}

function Verify(props: handleClose) {

    const [experience, setExperience] = useState<number>();
    const [notes, setNotes] = useState<string>();
    const [idUser] = useState<string>(props?.id);
    const [isloading, setIsLoading] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

    const addVerify = async () => {
        setIsLoading(true);
        try{
            await axios.put(`${API_URL}/users/${idUser}/verify`, {
                experience: experience,
            }).then(res => {
                new Noty({type: 'success', text: res.data.message, theme:'nest', progressBar: true,layout: 'topRight', timeout: 3000 }).show();
            })
        }catch(e) {
            if(e instanceof AxiosError){
                new Noty({
                  type: 'error',
                  text: e.response?.data.message,
                  timeout: 3000,
                  theme:'nest',
                  progressBar: false,
                  layout: 'topRight',
                }).show();
              }
        } finally {
            setIsLoading(false);
            props.hanldeClose();
            props.closeEditMenu();
        }
    }

    return(
        isloading ? <SuspenseLoader/> : 
        <Box py={3} px={3}>
            <Box mb={3}>
                <Input
                type="text"
                placeholder="Experience Years"
                value={experience}
                {...register("setExperienceValidation", {
                required: "Please Insert setExperience Number",
                pattern: { value: /^-?([1-8]?[0-9])\.{1}\d{1,6}/, message: "invalid setExperience" },
                })}
                required
                fullWidth
                onChange={(e) => setExperience(+e.target.value)}
                />
                {errors.setExperienceValidation && <ErrorMessage message={errors.setExperienceValidation.message} />}
            </Box>
            {/* <Box mb={3}>
                <Input
                type="text"
                placeholder="Notes"
                value={notes}
                {...register("setNotesValidation", {
                    required: "Please Insert setNotes Number",
                    pattern: { value: /^[a-z A-Z0-9\u0621-\u064A]*$/, message: "invalid setNotes" },
                })}
                required
                fullWidth
                onChange={(e) => setNotes(e.target.value)}
                />
                    {errors.setNotesValidation && <ErrorMessage message={errors.setNotesValidation.message} />}
            </Box> */}
            <Box mb={2}>
              <Button sx={{marginTop: '3px'}} color="info"  fullWidth variant="contained" onClick={addVerify} >add Verify</Button>
          </Box>
        </Box>
    )
}

export default Verify;