import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { app } from '../../../firebase';
import {
  getFirestore,
  collection,
  onSnapshot,
  deleteDoc,
  doc,
  updateDoc,
  orderBy,
  limit,
  query,
  startAfter,
  limitToLast,
  endBefore,
  DocumentReference,
  getDoc,
} from 'firebase/firestore';
import { ReviewsConverter } from 'src/firebase/firestore/convertors/reviews.converter';
import SuspenseLoader from 'src/components/SuspenseLoader';

import { UsersConverter } from 'src/firebase/firestore/convertors/users.convertor';
import { useSearchParams } from 'react-router-dom';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Users } from 'src/firebase/firestore/users.type';
import { Reviews } from 'src/firebase/firestore/reviews.type';
// import SearchBar from 'src/components/SearchBar';

const firestore = getFirestore(app);
const reviewsCollection = collection(firestore, 'reviews').withConverter<Reviews>(
  new ReviewsConverter()
);
const usersCollection = collection(firestore, 'users').withConverter(
  new UsersConverter()
);


function DataTable() {
  const [fbReviews, setFbReviews] = useState([]);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [id, setId] = useState<string>();
  const [isloading, setIsLoading] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  // let usersSnapshot: Unsubscribe  = null;
  const getUserFromFireStore = async (userRef: DocumentReference): Promise<void> => {
    const userSessionData = sessionStorage.getItem(userRef.path);
    if(!!userSessionData) {
      return;
    }
    const user = await getDoc(userRef.withConverter<Users>(new UsersConverter()));
    if(!user.exists()) {
      sessionStorage.setItem(userRef.path, JSON.stringify({displayName: 'حساب محذوف'}));
      return;
    }
    const data = user.data();
    sessionStorage.setItem(userRef.path, JSON.stringify(data));
  }
  const getUserFromSession = (userRef: DocumentReference<Users>): Users => {
    const userSessionData = sessionStorage.getItem(userRef.path);
    if(!!userSessionData) {
      return JSON.parse(userSessionData) as Users;
    }
    return null;
  }
  useEffect(()  => {
    getReviews();
}, []
  );
  const getReviews = async (firstItem: any = undefined, lastItem: any = undefined): Promise<any> => {
    if(!!firstItem){
      onSnapshot(query(reviewsCollection, orderBy('creationDate', 'desc'), limitToLast(10), endBefore(firstItem.creationDate) ), dealWithReviewsChanges)
      setPage(page - 1);
    }else if(!!lastItem){
      onSnapshot(query(reviewsCollection, orderBy('creationDate', 'desc'),limit(10), startAfter(lastItem.creationDate)), dealWithReviewsChanges)
      setPage(page + 1);
    }else{
      onSnapshot(query(reviewsCollection, orderBy('creationDate', 'desc'), limit(10)), dealWithReviewsChanges)
    }
  }

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete: () => void = async () => {
    setOpenDelete(false);
  };
 
  const handleDeleteItem = async () => {
    setIsLoading(true)
    if (id) {
      await deleteDoc(doc(reviewsCollection, id));
    }
    setOpenDelete(false);
    setIsLoading(false)
  };
  const handleClickApproved = async (id: string) => {
    var approvedDate = {};
    approvedDate["approval"] = {approved_by: 'admin@sahel.com.ly',approved_on: new Date() };
    await updateDoc(doc(collection(firestore,"reviews"), `${id}`), approvedDate)
  }

  return (
    <>
      <Card>
        <CardHeader
          action={
            <Box >
              <ButtonGroup>
              { 
                <Button disabled={page === 1} style={{margin: '0px'}} onClick={() => getReviews( fbReviews[0] ) }>Previous</Button>
              }

              {
                <Button disabled={fbReviews.length < 10} style={{margin: '0px'}} onClick={() => getReviews(undefined, fbReviews[fbReviews.length - 1])}>Next</Button>
              }
              </ButtonGroup>
            </Box>
          }
          title="Reviews Table"/>
        <Divider />
        { isloading ? <SuspenseLoader/> :
          <Dialog
              open={openDelete}
              onClose={handleCloseDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this item ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDelete}>Cancel</Button>
                <Button onClick={handleDeleteItem} autoFocus>
                    Delete
                </Button>
              </DialogActions>
          </Dialog>
          }
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell size='small'>Reviewed</TableCell>
                <TableCell size='small'>Reviewer</TableCell>
                <TableCell size='small'>rating</TableCell>
                <TableCell size='small'>message</TableCell>
                <TableCell size='small'>creation date</TableCell>
                <TableCell size='small' align="center">Approve</TableCell>
                <TableCell size='small' align="center">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fbReviews.map((review) => {
                return (
                  <TableRow key={review.id}>
                    <TableCell>
                      <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                      >
                        {getUserFromSession(review.reviewed_id as DocumentReference)?.display_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                      >
                        {getUserFromSession(review.reviewer_id as DocumentReference)?.display_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {review.rating}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {review.message}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        fontSize={12}
                      >
                        {new Intl.DateTimeFormat('en-US', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric'
                        }).format((review.creationDate.seconds)*1000)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        color="info"
                        onClick={() => handleClickApproved(review.id)}
                        disabled={review.approval.approved_by != undefined ? true : false }
                      >
                        approved
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        color="info"
                        onClick={() => {
                          setId(review.id);
                          handleClickOpenDelete();
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );

  async function dealWithReviewsChanges(list) {
      let items = [];
      for (let i = 0; i < list.docs.length; i++) {
        const doc = list.docs[i];
        const data = doc.data();
        items.push({ key: doc.id, ...data });
        await getUserFromFireStore(data.reviewed_id);
        await getUserFromFireStore(data.reviewer_id);
      }
      setFbReviews(items);
  }
}

export default DataTable;
