import { Palette } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = [
    {
        id: number;
        zone: string;
        cityOfZone: string;
        latitude: number,
        longitude: number,
    }
]
type ZoneAction = {
    id: number;
    zone: string;
    cityOfZone: string;
    latitude: number,
    longitude: number,
}

const initialState: InitialState = [
  {
    id: 0,
    zone: "Alshwahda",
    cityOfZone: "Misrata",
    latitude: 12.2121,
    longitude: 32.23232,
  },
];

export const zoneSlice = createSlice({
  name: "zone",
  initialState,
  reducers: {
    addZone: (state, action: PayloadAction<ZoneAction>) => {
      state.push(action.payload);
    },
    deleteZone: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      if (id == null || id === undefined) {
        return;
      }
      const index = state.findIndex((d) => d.id === id);
      state.splice(index, 1);
    },
  },
});

export const { addZone, deleteZone } = zoneSlice.actions;

export default zoneSlice.reducer;