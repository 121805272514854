import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { Outlet, useParams } from "react-router";
import { useState } from "react";
import Profile from "./Profile";

function UserProvider() {
    const params = useParams();
    
    const [idp, setIdp] = useState(params.id);
  return (
    <>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item m={3} xs={12}>
            <Profile/>
          </Grid>
        </Grid>
      </Container>
      <Outlet/>
    </>
  )
}

export default UserProvider;