import {
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SetOptions,
  SnapshotOptions,
  WithFieldValue
} from 'firebase/firestore';
import { Users } from '../users.type';

export class UsersConverter implements FirestoreDataConverter<Users> {
  toFirestore(modelObject: any): DocumentData;
  toFirestore(
    modelObject: any,
    options: SetOptions
  ): DocumentData;
  toFirestore(
    modelObject: Users,
    options?: unknown
  ): import('@firebase/firestore').DocumentData {
    return { ...modelObject };
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions
  ): Users {
    const data = snapshot.data();
    const users: Users = {
      id: snapshot.id,
      display_name: data.display_name,
      photo_url: data.photo_url,
      phone: data.phone_number,
      city: data.city,
      area: data.area,
      created_time: data.created_time,
      isProvider: !!data.provider
    };

    return users;
  }
}
