import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SetOptions, SnapshotOptions } from "firebase/firestore";
import { PopularServices } from "../popularServices.type";

export class PopularServicesConverter implements FirestoreDataConverter<PopularServices>{
    toFirestore(modelObject: any): DocumentData;
    toFirestore(modelObject: any, options: SetOptions): DocumentData;
    toFirestore(modelObject: PopularServices, options?: unknown): import("@firebase/firestore").DocumentData {
        return { ...modelObject };
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): PopularServices 
    {
        const data = snapshot.data();
        const popularServices: PopularServices = {
            id: snapshot.id,
            service: data.service
        };

        return popularServices;
    }

}