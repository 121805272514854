import { getFirestore, collection, onSnapshot, getDocs } from 'firebase/firestore';
import { ServiceConverter } from 'src/firebase/firestore/convertors/services.convertor';
import { Service } from 'src/firebase/firestore/service.type';
import { app } from '../../../firebase';

const firestore = getFirestore(app);
const servicesCollection = collection(firestore, 'services').withConverter(
  new ServiceConverter()
);

export async function GetServices(): Promise<Service[]> {

  if(!sessionStorage.getItem('Services')){
    const services = (await getDocs<Service>(servicesCollection)).docs.map(c=>c.data());
    sessionStorage.setItem('Services',JSON.stringify(services))
    return services;
  }
  const cacheServices: Service[] = JSON.parse( sessionStorage.getItem('Services'));
  return cacheServices;
}
