import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { app } from '../../../firebase';
import {
  getFirestore,
  collection,
  onSnapshot,
  deleteDoc,
  doc,
  getDoc,
  DocumentReference,
  orderBy,
  query,
} from 'firebase/firestore';
import { ServiceRequestsConverter } from 'src/firebase/firestore/convertors/serviceRequests.converter';
import SuspenseLoader from 'src/components/SuspenseLoader';

import { UsersConverter } from 'src/firebase/firestore/convertors/users.convertor';
import { Users } from 'src/firebase/firestore/users.type';
import { ServiceRequests } from 'src/firebase/firestore/serviceRequests.type';
// import SearchBar from 'src/components/SearchBar';

const firestore = getFirestore(app);
const serviceRequestsCollection = collection(firestore, 'service_requests').withConverter(
  new ServiceRequestsConverter()
);
const usersCollection = collection(firestore, 'users').withConverter(
  new UsersConverter()
);


function DataTable() {
  const [fbServiceRequests, setFbServiceRequests] = useState<ServiceRequests[]>([]);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>();
  const [isloading, setIsLoading] = useState<boolean>(false);
  const getUserFromFireStore = async (userRef: DocumentReference): Promise<void> => {
    const userSessionData = sessionStorage.getItem(userRef.path);
    if (!!userSessionData) {
      return;
    }
    const user = await getDoc(userRef.withConverter<Users>(new UsersConverter()));
    if(!user.exists()) {
      sessionStorage.setItem(userRef.path, JSON.stringify({display_name: 'حساب محذوف'}));
      return;
    }
    const data = user.data();
    sessionStorage.setItem(userRef.path, JSON.stringify(data));
  }

  const getUserFromSession = (userRef: DocumentReference<Users>): Users => {

      const userSessionData = sessionStorage.getItem(userRef.path);
      if (!!userSessionData) {
        return JSON.parse(userSessionData) as Users;
      }
    return null;
  }

  useEffect(() => {
    getServiceRequests();
    // getUsers();
  }, []
  );

  const getServiceRequests = async (): Promise<any> => {
    onSnapshot(query(serviceRequestsCollection,orderBy('creation_time','desc')), async (d) => {
      const serviceRequests: ServiceRequests[] = [];
      for (let i = 0; i < d.docs.length; i++) {
        const data = d.docs[i].data();
        await getUserFromFireStore(data.user);
        serviceRequests.push(data);
      }
      setFbServiceRequests(serviceRequests);
    })
  }

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete: () => void = async () => {
    setOpenDelete(false);
  };

  const handleDeleteItem = async () => {
    setIsLoading(true)
    if (deleteId) {
      await deleteDoc(doc(serviceRequestsCollection, deleteId));
    }
    setOpenDelete(false);
    setIsLoading(false)
  };

  return (
    <>
      <Card>
        <CardHeader title="Service Requests Table" />
        <Divider />
        {isloading ? <SuspenseLoader /> :
          <Dialog
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDelete}>Cancel</Button>
              <Button onClick={handleDeleteItem} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        }
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell size='small'>Name</TableCell>
                <TableCell size='small'>Created Time</TableCell>
                <TableCell size='small'>Service Name</TableCell>
                <TableCell size='small'>Description</TableCell>
                <TableCell size='small' align="center">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fbServiceRequests.map((sr) => {
                const user = getUserFromSession(sr.user);
                return (
                  <TableRow key={sr.id}>
                    <TableCell>
                      {user?.isProvider?<a href={`/provider/${sr.user.id}`}><Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {user?.display_name}
                      </Typography></a>:<Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {user?.display_name}
                      </Typography>}
                      
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        fontSize={13}
                      >
                        {new Intl.DateTimeFormat('en-US', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric'
                        }).format((sr?.creation_time?.seconds) * 1000)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {sr.service_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                      >
                        {sr.description}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        color="info"
                        onClick={() => {
                          setDeleteId(sr.id);
                          handleClickOpenDelete();
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}

export default DataTable;
