import {
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SetOptions,
  SnapshotOptions,
  WithFieldValue
} from 'firebase/firestore';
import { Service } from '../service.type';

export class ServiceConverter implements FirestoreDataConverter<Service> {
  toFirestore(modelObject: any): DocumentData;
  toFirestore(
    modelObject: any,
    options: SetOptions
  ): DocumentData;
  toFirestore(
    modelObject: Service,
    options?: unknown
  ): import('@firebase/firestore').DocumentData {
    return { ...modelObject };
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions
  ): Service {
    const data = snapshot.data();
    const service: Service = {
      id: snapshot.id,
      imageUrl: data.imageUrl,
      name: data.name
    };

    return service;
  }
}
