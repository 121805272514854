import { configureStore } from "@reduxjs/toolkit";
import deviceReducer from "../../DeviceSlice";
import cityReducer from "../../citySlice";
import zoneReducer from "../../zoneSlice";

const store = configureStore({
    reducer: {
        device: deviceReducer,
        city: cityReducer,
        zone: zoneReducer,
    },
})

export default store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch