import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';

function TableDash() {
  return (
    <Card>
      <CardHeader action={<Box width={150}></Box>} title="Table statis" />
      <Divider />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>City</TableCell>
              <TableCell align='center'>zones Count</TableCell>
              <TableCell align='center'>devices count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align='center'>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  Misrata
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  17
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  25
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='center'>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  Tripoli
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  26
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  35
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='center'>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  Banghazi
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  21
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  25
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='center'>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  Srit
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  7
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  13
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
export default TableDash;
