import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SetOptions, SnapshotOptions } from "firebase/firestore";
import { City } from "../city.type";

export class CityConverter implements FirestoreDataConverter<City> {
    toFirestore(modelObject: any): DocumentData;
    toFirestore(modelObject: any, options: SetOptions): DocumentData;
    toFirestore(modelObject: City, options?: unknown): import("@firebase/firestore").DocumentData {
        delete modelObject.path;
        return { ...modelObject };
    }
    
    fromFirestore(
        snapshot: QueryDocumentSnapshot<DocumentData>,
        options?: SnapshotOptions
    ): City {
        const data = snapshot.data();
        const city: City = {
            name: data.name,
            id: snapshot.id,
            center: data.center,
            enabled: data.enabled,
            path: snapshot.ref.path
        };
        
        return city;
    }
    
}