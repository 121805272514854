import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { Outlet } from "react-router";
import DataTable from "./DataTable";

function ReportsView() {
    return (
      <>
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item m={3} xs={12}>
              <DataTable />
            </Grid>
          </Grid>
        </Container>
        <Outlet/>
      </>
    )
  }
  
  export default ReportsView;