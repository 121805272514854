import { Navigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import SignInScreen from './firebase/login';
import Services from './content/dashboards/ServicesPage';
import { Suspense, useState } from 'react';

import PrivateRoute from './content/dashboards/PrivateRoute';

import {app} from './firebase'
import {getAuth} from 'firebase/auth'
import Cities from './content/dashboards/Cities';
import Zones from './content/dashboards/Zones';
import SidebarLayout from './layouts/SidebarLayout';
import SuspenseLoader from './components/SuspenseLoader';
import Dashboard from './content/dashboards/Dashboard';
import City from './content/statis/City';
import Zone from './content/statis/Zone';
import SubServices from './content/dashboards/SubServices';
import UserProvider from './content/dashboards/Providers/UserProvider';
import Users from './content/dashboards/Users';
import Posts from './content/dashboards/Posts';
import ServiceRequests from './content/dashboards/ServiceRequests';
import Reviews from './content/dashboards/Reviews';
import ProviderProfile from './content/dashboards/Providers/ProviderProfile';
import AdsBanner from './content/dashboards/AdsBanner';
import ReportsView from './content/dashboards/ReportsView';
import PopularServices from './content/dashboards/PopularServices';
import axios from 'axios';
import ExpiredProvider from './content/dashboards/Providers/ExpiredProviders';
import NewProvider from './content/dashboards/Providers/NewProviders';
import ArchivedProvider from './content/dashboards/Providers/ArchivedProviders';
const auth = getAuth(app);

axios.interceptors.request.use(
  async config => {
    config.headers['Authorization'] = 'Bearer ' + await auth?.currentUser?.getIdToken();
    return config
  },
  error => {
    Promise.reject(error)
  },{synchronous:false}
)

function App() {
  const [loading, setLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(auth.currentUser);
  auth.onAuthStateChanged((user)=>{
    if(user?.email=="admin@sahel.com.ly"){
      setIsAuth(user);
    } else if (user != null ) {
      auth.signOut();
    }
    setLoading(false)
  });

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
          {!loading ?
          (
            <Router>
              <Routes>
                <Route path="/login" element={<SignInScreen/>} />
                <Route path="/" element={<PrivateRoute auth={isAuth} />}>                
                  <Route path="" element={<SidebarLayout/>} >
                    <Route path='/' element={<Navigate to="/dashboard"/>}/>
                    <Route path='/dashboard' element={<Dashboard/>} />
                    <Route path='/services' element={<Services/>} />
                    <Route path='/sub-services' element={<SubServices/>} />
                    <Route path='/popular-services' element={<PopularServices/>} />
                    <Route path='/cities' element={<Cities/>} />
                    <Route path='/zones' element={<Zones/>} />
                    <Route path='/reviews' element={<Reviews/>} />
                    <Route path='/reports' element={<ReportsView/>} />
                    <Route path='/provider/:id' element={<ProviderProfile/>} /> {/* Test Provider Profile */}
                    <Route path='/users' element={<Users/>}/>
                    <Route path='/posts' element={<Posts/>}/>
                    <Route path='/ads-banner' element={<AdsBanner/>}/>
                    <Route path='/service-req' element={<ServiceRequests/>}/>
                    <Route path='/provider/active' element={<UserProvider/>} />
                    <Route path='/provider/expired' element={<ExpiredProvider/>} />
                    <Route path='/provider/new' element={<NewProvider/>} />
                    <Route path='/provider/archived' element={<ArchivedProvider/>} />
                    <Route path='/statis/city' element={<City/>} />
                    <Route path='/statis/zone' element={<Zone/>} />
                  </Route> 
                </Route>
              </Routes>
            </Router>
          )
          :
          <SuspenseLoader/>
          }
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
