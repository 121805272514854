import { Button, Dialog, DialogContent, Input, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Noty from 'noty';
import ErrorMessage from "../error";
import { collection, doc, DocumentReference, getFirestore, onSnapshot, updateDoc } from "firebase/firestore";
import { app } from "src/firebase";
import SuspenseLoader from "src/components/SuspenseLoader";
import { UsersConverter } from "src/firebase/firestore/convertors/users.convertor";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 } from 'uuid';
import { Users } from "src/firebase/firestore/users.type";
import axios, { AxiosError } from "axios";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CityConverter } from "src/firebase/firestore/convertors/city.converer";
import { ZoneConverter } from "src/firebase/firestore/convertors/zone.converter";
import MakeProvider from "./MakeProvider";
import { API_URL } from "src/constants";

const firestore = getFirestore(app);
const usersCollection = collection(firestore,'users').withConverter(new UsersConverter());
const citiesCollection = collection(firestore,'cities').withConverter(new CityConverter());;
const areasCollection = collection(firestore,'city_areas').withConverter(new ZoneConverter());;
const storage = getStorage(app);

type handleClose = {
  handleClose:  () => void;
  users: any;
  makeProvider: boolean;
}

function AddNewUserComponent(props: handleClose) {
  const [id, setId] = useState<string>(props.users?.uid??'');
  const [imageUrl, setImageUrl] = useState<any>(props.users?.image??'');
  const [image, setImage] = useState<any>();
  const [displayName, setDisplayName] = useState<string>(props.users?.displayName);
  const [phone, setPhone] = useState<string>(props.users?.phoneNumber);
  const [city, setCity] = useState<string>(props.users?.city || '');
  const [area, setarea] = useState<string>(props.users?.area || '');
  const [password, setPassword] = useState<string>('');
  const [cities, setCities ] = useState([]);
  const [areas, setareas ] = useState([]);
  const [filteredAreas, setFilteredareas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [makeProvider] = useState<boolean>(!!props.makeProvider);
  const [userId, setUserId] = useState<string>()
  const [openMakeProvider, setOpenMakeProvider] = useState<boolean>(false);

  
  const [openPass, setOpenPass] = useState<boolean>(false);
  const onSubmit = async () => {
    setIsLoading(true); 
    if(!!image) {
      const imageRef = ref(storage, `cms_uploads/users/images/${image.name + v4()}`);
      var url = await (await uploadBytes(imageRef, image).then((snapshot) =>  getDownloadURL(snapshot.ref)))
    }else {
      url = imageUrl
    }
    const useresModel: Users = {
      photo_url: url,
      display_name: displayName,
      city: doc(firestore, `${city}`),
      area: doc(firestore, `${area}`)
    };
    try{
      if(!id) {
        await addUser();
      } else {
        await updateDoc(doc(usersCollection,id),useresModel);
        props.handleClose();
      }
      
  }catch(e){
    if(e instanceof AxiosError){
      new Noty({
        type: 'error',
        text: e.response?.data.message,
        timeout: 3000,
        theme:'nest',
        progressBar: false,
        layout: 'topRight',
      }).show();
    }
  }finally{
      setIsLoading(false);
    }
  }

  const addUser = async () => {
      await axios.post(`${API_URL}/users/add`, {
        phone: phone,
        name: displayName,
        area: area,
        city: city
      }).then(res => {
        setUserId(res.data.uid);
        setPassword(res.data.password);
        setOpenPass(true);
        new Noty({type: 'success', text: res.data.message, theme:'nest', progressBar: true,layout: 'topRight', timeout: 3000 }).show();
      })
  }

  const handleClosePassword = () => {
    setOpenPass(false);
    props.handleClose();
  }

  const handleCloseMakeProvider: () => void = async () => {
    setOpenMakeProvider(false);
  };
  const handleOpenMakeProvider: () => void = async () => {
    setOpenMakeProvider(true);
    props.handleClose();
  };
  useEffect(() => {
    const getCities = async (): Promise<any>=>{
      onSnapshot(citiesCollection, (d) => {
          setCities(d.docs.map(d => {return {...d.data(),path:d.ref?.path}}));
      });
   }
   getCities();
    const getareas = async (): Promise<any>=>{
      onSnapshot(areasCollection, (d) => {
          setareas(d.docs.map(d => {return {...d.data(),path:d.ref?.path}}));
      });
   }
   getareas();
  }, [])

  useEffect(() => {
    filterAreas(city);
  }, [city,areas])
  
  const handleLockUser = async () => {
    try{
      await axios.put(`${API_URL}/users/${props.users.uid}/lock`).then(res => {
        new Noty({type: 'success', text: res.data.message, theme:'nest', progressBar: true,layout: 'topRight', timeout: 3000 }).show();
      });
    } catch(e) {
      if(e instanceof AxiosError){
        new Noty({
          type: 'error',
          text: e.response?.data.message,
          timeout: 3000,
          theme:'nest',
          progressBar: false,
          layout: 'topRight',
        }).show();
      }
    } finally {
      props.handleClose();
    }
  }
  const handleUnLockUser = async () => {
    try{
      await axios.put(`${API_URL}/users/${props.users.uid}/unlock`).then(res => {
        new Noty({type: 'success', text: res.data.message, theme:'nest', progressBar: true,layout: 'topRight', timeout: 3000 }).show();
      });
    } catch(e) {
      if(e instanceof AxiosError){
        new Noty({
          type: 'error',
          text: e.response?.data.message,
          timeout: 3000,
          theme:'nest',
          progressBar: false,
          layout: 'topRight',
        }).show();
      }
    } finally {
      props.handleClose();
    }
  } 
  // function use Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  // set City dropdown menu
  const handleChange = (e) => {
    setCity(e.target.value);
  };

  function filterAreas(city: string) {
    console.log(city);
    const filteredCities = areas.filter((area) => (area.city as DocumentReference)?.path == city && area.enabled);

    setFilteredareas(filteredCities);
  }
  return (
    isLoading? <SuspenseLoader/> : 
    <>
      <Dialog open={openPass} onClose={handleClosePassword}>
        <DialogContent>
          <p>The Password is : {password}</p>
          <p>The Phone is : {phone}</p>
          {makeProvider && <Button onClick={()=> handleOpenMakeProvider()}>Make Provider</Button>}
        </DialogContent>
      </Dialog>
      <Dialog open={openMakeProvider} onClose={handleCloseMakeProvider}>
          <DialogContent>
            <MakeProvider uid={userId} handleClose={handleCloseMakeProvider} />
          </DialogContent>
        </Dialog>
      <Box
        bgcolor="info"
        borderRadius="lg"
        mx={1}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <Typography  variant="h3" fontWeight="bold" color="info" mt={2}>
          {props.users? 'Update User' : 'Add User'} 
        </Typography>
      </Box>
      <Box  py={3} px={3}>
        <Box component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
          <Box mb={3}>
                <Input
                type="text"
                placeholder="Dispaly Name"
                value={displayName}
                {...register("displayNameValidation", {
                  required: "Please Insert Name Of Provider",
                  pattern: { value: /^[a-z A-Z0-9\u0621-\u064A]*$/, message: "invalid name" },
                })}
                fullWidth
                onChange={(e) => setDisplayName(e.target.value)}
                />
                  {errors.displayNameValidation && <ErrorMessage message={errors.displayNameValidation.message} />}
            </Box>
            <Box mb={3}>
                <Input
                type="text"
                placeholder="Phone"
                value={phone}
                readOnly={props.users&& true}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
                />
                  {errors.phoneValidation && <ErrorMessage message={errors.phoneValidation.message} />}
            </Box>
            <Box mb={3}>
              <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">City</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={city}
                    label="City"
                    required
                    onChange={(e)=>handleChange(e)}
                    sx={{ p: 0, my: 1 }}
                  >
                    {
                      cities.map((city)=>{
                        if(city.enabled)
                        return <MenuItem key={city?.id} value={city?.path}> {city?.name} </MenuItem> 
                      })
                    }
                  </Select>
                </FormControl>
            </Box>
            <Box mb={3}>
              <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Area</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={area}
                    required
                    label="Area"
                    onChange={(e)=> setarea(e.target.value)}
                    sx={{ p: 0, my: 1 }}
                  >
                    {
                      filteredAreas.map(z=>{
                        return <MenuItem key={z?.id} value={z?.path}> {z?.name} </MenuItem>
                      })
                    }
                    
                  </Select>
                </FormControl>
            </Box>
            <Box mb={3} sx={{display: 'flex'}}>
              {props?.users?.locked ? (<Button color="info" variant="contained"  sx={{margin: 'auto'}} onClick={handleUnLockUser} >UnLock</Button>) :
               ( <Button color="info" variant="contained" disabled={!props.users && true}  sx={{margin: 'auto'}} onClick={handleLockUser} >Lock</Button>)
              }
              
              
            </Box>
            <Box mb={1}>
                <Button color="info" type="submit" fullWidth >{props.users? 'Update': 'Add'}</Button>
            </Box>
        </Box>
      </Box>
    </>
  )

  
}

export default AddNewUserComponent;