import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { app } from "src/firebase";
import SuspenseLoader from "src/components/SuspenseLoader";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 } from 'uuid';
import axios, { AxiosError } from "axios";
import Compressor from 'compressorjs';
import { API_URL } from "src/constants";



const storage = getStorage(app);

type handleClose = {
  handleClose:  () => void;
  userProvider: any;
  closeEditMenu: () => void;
}

function EditProfile(props: handleClose) {
  const [id, setId] = useState<string>(props.userProvider?.uid);
  const [imageUrl, setImageUrl] = useState<any>(props.userProvider?.photoURL);
  const [image, setImage] = useState<File>();
  const [processedImage, setProcessedImage] = useState<File | Blob>();
  const [isLoading, setIsLoading] = useState(false);

  console.log('URL image Out Submit', imageUrl);

  const onSubmit = async () => {
    setIsLoading(true);   
    try {
      if(processedImage !== undefined) {
        const imageRef = ref(storage, `cms_uploads/services/images/${image.name + v4()}`);
        const imageDownloadUrl = await uploadBytes(imageRef, processedImage).then((snapshot) =>  getDownloadURL(snapshot.ref))
        setImageUrl(imageDownloadUrl);
        await axios.put(`${API_URL}/users/${id}/profile-picture`, { url: imageDownloadUrl }).then(res => {
          console.log(res)
        });
      }
      
    } catch(e) {
      if(e instanceof AxiosError){
        new Noty({
          type: 'error',
          text: e.response?.data.message,
          timeout: 3000,
          theme:'nest',
          progressBar: false,
          layout: 'topRight',
        }).show();
      }
    } finally {
      setIsLoading(false);
      props.handleClose();
      props.closeEditMenu();
    }

  };

  const handleDeleteImageProfile = async () => {
    setIsLoading(true);
    try{
      await axios.put(`${API_URL}/users/${id}/profile-picture`).then(res => {
        console.log(res)
      })
    } catch(e) {
      if(e instanceof AxiosError){
        new Noty({
          type: 'error',
          text: e.response?.data.message,
          timeout: 3000,
          theme:'nest',
          progressBar: false,
          layout: 'topRight',
        }).show();
      }
    } finally {
      setIsLoading(false);
      props.handleClose();
      props.closeEditMenu();
    }
  }

  // function use Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  return (
    isLoading? <SuspenseLoader/> : 
    <>
      <Box
        bgcolor="info"
        borderRadius="lg"
        mx={1}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <Typography  variant="h3" fontWeight="bold" color="info" mt={2}>
           User Provider
        </Typography>
      </Box>
      <Box py={3} px={3}>
        <Box pb={3} sx={{textAlign: 'center'}}>
          <img src={image?URL.createObjectURL(image):imageUrl?imageUrl:"static/images/avatars/6.png"} alt='profile picture' width={128} height={128} style={{borderRadius:64}}/>
        </Box>
        <Box component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
          <Box mb={3}>
            <input 
            type="file"
            id="image"
            name="image"
            required
            accept='image/*'
            onChange={(event) => {
              setImage(event.target.files[0]);
              new Compressor(event.target.files[0],{
                maxWidth:500,
                quality: 0.8,
                success: async (result) => {
                  setProcessedImage(result);
                }
              })
            }}
            />
          </Box>
          <Box mb={1}>
              <Button color="info" disabled={!image} type="submit" fullWidth >Upload</Button>
              <Button color="error" disabled={!imageUrl} onClick={handleDeleteImageProfile} fullWidth >Delete Image</Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default EditProfile;