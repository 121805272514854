import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SetOptions, SnapshotOptions } from "firebase/firestore";
import { ServiceRequests } from "../serviceRequests.type";

export class ServiceRequestsConverter implements FirestoreDataConverter<ServiceRequests>{
    toFirestore(modelObject: any): DocumentData;
    toFirestore(modelObject: any, options: SetOptions): DocumentData;
    toFirestore(modelObject: ServiceRequests, options?: unknown): import("@firebase/firestore").DocumentData {
        return { ...modelObject };
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): ServiceRequests 
    {
        const data = snapshot.data();
        const serviceRequests: ServiceRequests = {
            id: snapshot.id,
            description: data.description,
            creation_time: data.creation_time,
            service_name: data.service_name,
            user: data.user,
        };

        return serviceRequests;
    }

}