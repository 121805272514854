import {
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SetOptions,
  SnapshotOptions,
  WithFieldValue
} from 'firebase/firestore';
import { Packages } from '../packages.type';

export class PackagesConverter implements FirestoreDataConverter<Packages> {
  toFirestore(modelObject: any): DocumentData;
  toFirestore(
    modelObject: any,
    options: SetOptions
  ): DocumentData;
  toFirestore(
    modelObject: Packages,
    options?: unknown
  ): import('@firebase/firestore').DocumentData {
    return { ...modelObject };
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions
  ): Packages {
    const data = snapshot.data();
    const packages: Packages = {
      name: data.name,
      time: data.time,
      price: data.price
    };

    return packages;
  }
}
