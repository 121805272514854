import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, Input, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";

import SuspenseLoader from "src/components/SuspenseLoader";
import { API_URL } from "src/constants";
import ErrorMessage from "../../error";

type handleClose = {
  handleClose:  () => void;
  userProvider: any;
  closeEditMenu: () => void;
}


function EditContactInfo(props: handleClose) {
  const [id, setId] =useState<string>(props.userProvider?.uid);
  const [phoneOne, setPhoneOne] = useState<string>(props.userProvider?.contactInfo[0]);
  const [phoneTwo, setPhoneTwo] = useState<string>(props.userProvider?.contactInfo[1]);
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async () => {
      setIsLoading(true);
      try {
        await axios.put(`${API_URL}/users/${id}/contact-info`,{contactInfo: [phoneOne, phoneTwo]}).then(res => {
          console.log(res);
        })
      } catch(e) {
        if(e instanceof AxiosError){
          new Noty({
            type: 'error',
            text: e.response?.data.message,
            timeout: 3000,
            theme:'nest',
            progressBar: false,
            layout: 'topRight',
          }).show();
        }
      } finally {
        setIsLoading(false);
        props.handleClose();
        props.closeEditMenu();
      }
  };

    // function use Form
  const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
  } = useForm();

  return(
      isLoading? <SuspenseLoader/> : 
  <>
    <Box
      bgcolor="info"
      borderRadius="lg"
      mx={1}
      mt={-1}
      p={2}
      textAlign="center"
    >
      <Typography  variant="h3" fontWeight="bold" color="info" mt={2}>
          User Provider
      </Typography>
    </Box>
    <Box py={3} px={3}>
      <Box component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
        <Box mb={3}>
            <Input
            type="text"
            placeholder="Phone One"
            value={phoneOne}
            onChange={(e) => setPhoneOne(e.target.value)}
            fullWidth
            />
              {errors.phoneValidation && <ErrorMessage message={errors.phoneValidation.message} />}
        </Box>
        <Box mb={3}>
            <Input
            type="text"
            placeholder="Phone Two"
            value={phoneTwo}
            onChange={(e) => setPhoneTwo(e.target.value)}
            fullWidth
            />
              {errors.phoneValidation && <ErrorMessage message={errors.phoneValidation.message} />}
        </Box>
        <Box mb={1}>
            <Button color="info" type="submit" fullWidth >Update</Button>
        </Box>
      </Box>
    </Box>
  </>
  )
}

export default EditContactInfo;