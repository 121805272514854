import { Route, Navigate, RouteProps, Outlet } from 'react-router-dom';

 interface Props extends RouteProps {
     auth: any
 }
  const PrivateRoute = ({auth, ...routeProps}: Props) => {
     if (!auth) {
         return <Navigate to="/login" />;
     } else {
         <Route {...routeProps} />;
     }
     return <Outlet/>
 }


export default PrivateRoute;