import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { Outlet } from "react-router";
import TableData from "./TableData";

function SubServices() {
  return (
    <>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item m={3} xs={12}>
            <TableData />
          </Grid>
        </Grid>
      </Container>
      <Outlet/>
    </>
  )
}

export default SubServices;