import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SetOptions, SnapshotOptions } from "firebase/firestore";
import { Reviews } from "../reviews.type";

export class ReviewsConverter implements FirestoreDataConverter<Reviews>{
    toFirestore(modelObject: any): DocumentData;
    toFirestore(modelObject: any, options: SetOptions): DocumentData;
    toFirestore(modelObject: Reviews, options?: unknown): import("@firebase/firestore").DocumentData {
        return { ...modelObject };
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): Reviews 
    {
        const data = snapshot.data();
        const reviews: Reviews = {
            id: snapshot.id,
            message: data.message,
            creationDate: data.creationDate,
            rating: data.rating,
            reviewed_id: data.reviewed_id,
            reviewer_id: data.reviewer_id,
            approval: {
                approved_by: data.approval,
                approved_on: data.approval
            }
        };

        return reviews;
    }

}