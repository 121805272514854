import { Dialog, DialogContent, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Add from './Add';
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from 'react';
import { Container } from "@mui/system";
import { app } from '../../../firebase';
import { collection, getFirestore, onSnapshot } from "firebase/firestore";
import { Outlet } from "react-router";
import { PopularServicesConverter } from "src/firebase/firestore/convertors/PopularServices.converter";
import { SubServiceConverter } from "src/firebase/firestore/convertors/subServices.convertor";

// import DataTable from "./DataTable";

const firestore = getFirestore(app);
const popularServicesCollection = collection(firestore,'popular_services').withConverter(new PopularServicesConverter());
const subServicesCollection = collection(firestore, 'sub_services').withConverter(
    new SubServiceConverter()
  );

function PopularServices() {
    const [fbPopularServices, setFbPopularServices] = useState([]);
    const [fbSubServices, setFbSubServices] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const [id, setId] = useState<string>();
    const [test, setTest] = useState<any>();

    useEffect(() => {
        const getPopularServices = async (): Promise<any> => {
            onSnapshot(popularServicesCollection, (d) => {
                setFbPopularServices(d.docs.map(d => d.data()))
            })
        }
        getPopularServices();
        const getSubServices = async (): Promise<any> => {
            onSnapshot(subServicesCollection, (d) => {
              setFbSubServices(d.docs.map((d) => {return {...d.data(),path: d.ref.path}}));
            });
          };
          getSubServices();
    }, [])

    const handleOpenDialog = () => {
        setOpenEditDialog(true)
    }
    const handleCloseDialog = () => {
        setOpenEditDialog(false)
    }

    return (
      <>
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item m={3} xs={12}>
                
                {
                  fbPopularServices.map((pop) => {
                    return(
                        <>
                            <Dialog open={openEditDialog} onClose={handleCloseDialog}>
                                <DialogContent>
                                    <Add handleClose={handleCloseDialog} id={id}/>
                                </DialogContent>
                            </Dialog>
                            <Card key={pop.id} sx={{my: '50px'}}>
                                <CardContent sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <Typography fontSize={48}>{pop.id}</Typography>             
                                    <Box>
                                      {/* {
                                        (fbSubServices.find(sub => sub?.path == pop?.service?.path)?.icon || fbSubServices.find(sub => sub?.path == pop?.service?.path)?.image)
                                      } */}
                                      <img src={fbSubServices.find(sub=> sub?.path == pop?.service?.path)?.image} alt='sub-service image' width={128} height={128}/>
                                    </Box>
                                    <Typography fontSize={24}>{fbSubServices.find(sub=>sub?.path == pop?.service?.path)?.service_name}</Typography>
                                    <Button onClick={() => {setId(pop.id); handleOpenDialog()}}>Edit</Button>
                                </CardContent>
                            </Card>
                        </>
                      )
                        
                        
                        
                        
                    })
                }
              
              
            </Grid>
          </Grid>
        </Container>
        <Outlet/>
      </>
    )
  }
  
  export default PopularServices;