import { Button, Input, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useForm } from "react-hook-form";

import ErrorMessage from "../error";
import { collection, doc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import { app } from "src/firebase";
import SuspenseLoader from "src/components/SuspenseLoader";
import { ServiceConverter } from "src/firebase/firestore/convertors/services.convertor";
import { Service } from "src/firebase/firestore/service.type";
import { getDownloadURL, getStorage } from "firebase/storage";
import { ref, uploadBytes } from 'firebase/storage';
import { v4 } from 'uuid';

const firestore = getFirestore(app);
const servicesCollection = collection(firestore,'services').withConverter(new ServiceConverter());
const storage = getStorage(app);

type handleClose = {
    handleClose:  () => void;
    service: any;
}

function Add(props: handleClose) {
  const [id, setId] = useState<string>(props.service?.id);
  const [name, setName] = useState<string>(props.service?.name);
  const [image, setImage] = useState<any>();
  const [urlImage, setUrlImage] = useState<any>(props.service?.imageUrl);
  const [isLoading, setIsLoading] = useState(false);
  
  const onSubmit = async () => {
    setIsLoading(true); 
    if(image !== undefined) {
      const imageRef = ref(storage, `cms_uploads/services/images/${image.name + v4()}`);
      var url = await (await uploadBytes(imageRef, image).then((snapshot) =>  getDownloadURL(snapshot.ref)))
    }else {
      url = urlImage
    }
    const serviceModel: Service = {
      name: name,
      imageUrl:  url
    };
    if(id ==  undefined) {
      await setDoc(doc(servicesCollection), serviceModel);
    } else {
      await updateDoc(doc(servicesCollection,id),serviceModel);
    }
    setIsLoading(false);
    props.handleClose();
  };

  // function use Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  return (
    isLoading? <SuspenseLoader/> : 
    <>
      <Box
        bgcolor="info"
        borderRadius="lg"
        mx={1}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <Typography  variant="h3" fontWeight="bold" color="info" mt={2}>
          Add Services
        </Typography>
      </Box>
      <Box  py={3} px={3}>
        <Box component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
            <Box mb={3}>
                <Input
                type="text"
                placeholder="Service Name"
                value={name}
                {...register("deviceNameValidation", {
                  required: "Please Insert Name Of Device",
                  pattern: { value: /^[a-z A-Z0-9\u0621-\u064A]*$/, message: "invalid name" },
                })}
                fullWidth
                onChange={(e) => setName(e.target.value)}
                />
                  {errors.deviceNameValidation && <ErrorMessage message={errors.deviceNameValidation.message} />}
            </Box>
            <Box mb={3}>
              <input 
              type="file"
              id="image"
              name="image"
              accept='image/*'
              onChange={(event) => setImage(event.target.files[0])}
              />
            </Box>

            <Box mb={1}>
                <Button color="info" type="submit" fullWidth >{props.service? 'Update': 'Add'}</Button>
            </Box>
        </Box>
      </Box>
    </>
  )
}

export default Add;