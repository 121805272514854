import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { app } from '../../../firebase';
import {
  getFirestore,
  collection,
  onSnapshot,
  deleteDoc,
  doc,
  DocumentReference,
  getDoc,
  QuerySnapshot,
} from 'firebase/firestore';
import { ReportsConverter } from 'src/firebase/firestore/convertors/reports.converter';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { UsersConverter } from 'src/firebase/firestore/convertors/users.convertor';
import { Users } from 'src/firebase/firestore/users.type';
import { Reports } from 'src/firebase/firestore/reports.type';
// import SearchBar from 'src/components/SearchBar';

const firestore = getFirestore(app);
const reportsCollection = collection(firestore, 'reports').withConverter<Reports>(
  new ReportsConverter()
);
const usersCollection = collection(firestore, 'users').withConverter(
  new UsersConverter()
);

const getUser = async (userRef: DocumentReference<Users>): Promise<Users> => {
  const user = await getDoc(userRef);
  return user?.data();
}

function DataTable() {
  const [fbReports, setFbReports] = useState<Reports[]>([]);
  //const [fbUsers, setFbUsers] = useState({});
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [id, setId] = useState<string>();
  const [isloading, setIsLoading] = useState<boolean>(false);

  // let usersSnapshot: Unsubscribe  = null;
  const getUserFromFireStore = async (userRef: DocumentReference): Promise<void> => {
    const userSessionData = sessionStorage.getItem(userRef.path);
    if(!!userSessionData) {
      return;
    }
    const user = await getDoc(userRef.withConverter<Users>(new UsersConverter()));
    if(!user.exists()) {
      sessionStorage.setItem(userRef.path, JSON.stringify({displayName: 'حساب محذوف'}));
      return;
    }
    const data = user.data();
    sessionStorage.setItem(userRef.path, JSON.stringify(data));
  }
  const getUserFromSession = (userRef: DocumentReference<Users>): Users => {
    const userSessionData = sessionStorage.getItem(userRef.path);
    if(!!userSessionData) {
      return JSON.parse(userSessionData) as Users;
    }
    return null;
  }
  useEffect(() => {
    getReports();
    }, []
  );
  const getReports = async (): Promise<any> => {
    onSnapshot<Reports>(reportsCollection, dealWithReportsChanges)
  }

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete: () => void = async () => {
    setOpenDelete(false);
  };
 
  const handleDeleteItem = async () => {
    setIsLoading(true)
    if (id) {
      await deleteDoc(doc(reportsCollection, id));
    }
    setOpenDelete(false);
    setIsLoading(false)
  };

  return (
    <>
      <Card>
        <CardHeader title="Reports Table"/>
        <Divider />
        { isloading ? <SuspenseLoader/> :
          <Dialog
              open={openDelete}
              onClose={handleCloseDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this item ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDelete}>Cancel</Button>
                <Button onClick={handleDeleteItem} autoFocus>
                    Delete
                </Button>
              </DialogActions>
          </Dialog>
          }
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell size='small'>reporter</TableCell>
                <TableCell size='small'>message</TableCell>
                <TableCell size='small'>date</TableCell>
                <TableCell size='small'>review</TableCell>
                <TableCell size='small' align="center">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fbReports.map((report) => {
                return (
                  <TableRow key={report.id}>
                    <TableCell>
                      <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                      >
                      {getUserFromSession(report.reporter_id)?.display_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {report.message}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        fontSize={12}
                      >
                        {new Intl.DateTimeFormat('en-US', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric'
                        }).format((report.creationDate.seconds*1000))}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {report.review_id?.id}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        color="info"
                        onClick={() => {
                          setId(report.id);
                          handleClickOpenDelete();
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );

  async function dealWithReportsChanges(data: QuerySnapshot<Reports>) {
    let items = [];
    for(let i = 0; i < data.docs.length; i++) {
      const postData: Reports = data.docs[i].data();
      items.push({key: data.docs[i].id, ...postData });
      await getUserFromFireStore(postData.reporter_id as DocumentReference<Users>);
    }
    setFbReports(items);
}
}

export default DataTable;
