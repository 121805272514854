import { Button, Grid, Input, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Map from "../../../map";
import { useLoadScript } from "@react-google-maps/api";
import ErrorMessage from "../error";
import { collection, doc, getFirestore, setDoc, GeoPoint, updateDoc } from "firebase/firestore";
import { app } from "src/firebase";

// React Loading Test
import SuspenseLoader from "src/components/SuspenseLoader";
import { City } from "src/firebase/firestore/city.type";
import { CityConverter } from "src/firebase/firestore/convertors/city.converer";

const firestore = getFirestore(app);
const citiesCollection = collection(firestore,'cities').withConverter(new CityConverter());;

type handleClose = {
    handleClose:  () => void,
    city: any
}

function Add(props: handleClose) {
  
  const [id, setId] = useState<string>(props.city?.id);
  const [city, setCity] = useState<string>(props.city?.name);
  const [latitude, setLatitude] = useState<number>(props.city?.center?.latitude);
  const [longitude, setLongitude] = useState<number>(props.city?.center?.longitude);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    const cityModel: City = {
      name: city,
      center: new GeoPoint(latitude, longitude),
      enabled: true
    }
    if(id ==  undefined) {
      await setDoc(doc(citiesCollection), cityModel);
    } else {
      await updateDoc(doc(citiesCollection,id),cityModel);
    }
    setIsLoading(false);
    props.handleClose();
  }

  // function use Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  // Load Google Map Function
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDiQQ9sHOsaYWIpoOwop5Zbp_NXmlsVW1Q",
    language: "ar",
  });
  
  const mapChange = (data) => {
    setValue("cityLatitudeValidation", data?.lat, { shouldTouch: true, shouldValidate: true });
    setValue("cityLongitudeValidation", data?.lng, { shouldTouch: true, shouldValidate: true });
    setLatitude(data?.lat);
    setLongitude(data?.lng);
  };
    
  return (
      
    isLoading ?  <SuspenseLoader /> : 
      <>
        <Box
        bgcolor="info"
        borderRadius="lg"
        mx={1}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <Typography  variant="h3" fontWeight="bold" color="info" mt={2}>
            Add City
        </Typography>
      </Box>
      <Box  py={3} px={3}>
        <Box component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
            <Box mb={3}>
                <Input
                type="string"
                placeholder="ID"
                readOnly
                value={id}
                fullWidth
                />
            </Box>
            <Box mb={3}>
                <Input
                type="text"
                placeholder="City Name"
                value={city}
                {...register("cityNameValidation", {
                  required: "Please Insert Name Of City",
                  pattern: { value: /^[a-zA-Z\u0621-\u064A]*$/, message: "invalid name" },
                })}
                fullWidth
                onChange={(e) => setCity(e.target.value)}
                autoFocus
                />
                {errors.cityNameValidation && <ErrorMessage message={errors.cityNameValidation.message} />}
            </Box>
            <Box mb={3}>
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <Input
                    type="text"
                    placeholder="Latitude"
                    value={latitude}
                    {...register("cityLatitudeValidation", {
                      required: "Please Insert Latitude",
                      pattern: { value: /^-?([1-8]?[0-9])\.{1}\d{1,6}/, message: "invalid id" },
                    })}
                    fullWidth
                    onChange={(e) => setLatitude(+e.target.value)}
                    />
                      {errors.cityLatitudeValidation && <ErrorMessage message={errors.cityLatitudeValidation.message} />}
                  </Grid>
                  <Grid item xs={6}>
                  <Input
                    type="text"
                    placeholder="Longitude"
                    value={longitude}
                    {...register("cityLongitudeValidation", {
                      required: "Please Insert Latitude",
                      pattern: { value: /^-?([1-8]?[0-9])\.{1}\d{1,6}/, message: "invalid id" },
                    })}
                    fullWidth
                    onChange={(e) => setLongitude(+e.target.value)}
                    />
                      {errors.cityLongitudeValidation && <ErrorMessage message={errors.cityLongitudeValidation.message} />}
                  </Grid>
                </Grid>
            </Box>
            <Box mb={3}>
              {isLoaded && (
                <Map
                  center={{ lat: 32.352612, lng: 15.05911 }}
                  markerLat={latitude}
                  markerLng={longitude}
                  onChange={(data) => mapChange(data)}
                />
              )}
            </Box>
            <Box mb={1}>
                <Button color="info" type="submit" fullWidth >{props.city?'Update':'Add'}</Button>
            </Box>
        </Box>
      </Box>
    </> 
  )
}

export default Add;