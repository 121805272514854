import { Button, Card, CardHeader, Dialog, DialogActions, DialogContentText, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from "react";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import Add from './Add'
import { app } from '../../../firebase'
import { getFirestore, collection, onSnapshot, doc, updateDoc } from 'firebase/firestore'
import { CityConverter } from "src/firebase/firestore/convertors/city.converer";
import SuspenseLoader from "src/components/SuspenseLoader";

const firestore = getFirestore(app);
const citiesCollection = collection(firestore,'cities').withConverter(new CityConverter());
let currentCity = null;

function TableData() {
        
    const [Id, setId] = useState<string>();
    const [ fbCities, setCities ] = useState([]);
    const [openDisableCity, setOpenDisableCity] = useState<boolean>(false);
    const [openEnableCity, setOpenEnableCity] = useState<boolean>(false);
    const [isloading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        // onLoad();
        const getCities = async (): Promise<any>=>{
            onSnapshot(citiesCollection,(d)=>{
                setCities(d.docs.map(d=>d.data()));
            });
        }
        getCities();
    }, [])    

    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
   

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickOpenDisableCity = () => {
        setOpenDisableCity(true);
    };
    const handleClickOpenEnableCity = () => {
        setOpenEnableCity(true);
    };
    const handleCloseDisableCity: () =>  void = async () => {
        setOpenDisableCity(false);
    };
    const handleCloseEnableCity: () =>  void = async () => {
        setOpenEnableCity(false);
    };
    const handleDisableCity =  async ()  => {
        setIsLoading(true)
        // update City record "enabled" = false
        await updateDoc(doc(citiesCollection,Id),{"enabled":false})
        setOpenDisableCity(false);
        setIsLoading(false)
    };
    const handleEnableCity =  async ()  => {
        setIsLoading(true)
        await updateDoc(doc(citiesCollection,Id),{"enabled":true})
        setOpenEnableCity(false);
        setIsLoading(false)
    };

    const handleEditClick = (city: any) => {
        currentCity = city;
        setOpen(true);
    };
    const handleClose: () =>  void = async () => {
        setOpen(false);
        currentCity = null;
    };

    return (
        <Card>
            <CardHeader 
            action={
                <Box width={150}>
                    <Button
                        sx={{ mt: { xs: 2, md: 0 } }}
                        variant="contained"
                        startIcon={<AddTwoToneIcon fontSize="small" />}
                        onClick={handleClickOpen}
                    >
                    Add City
                    </Button>
                </Box>
            }
            title="Cities Table" />
            <Divider />
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Add handleClose={handleClose} city={currentCity} />
                </DialogContent>
            </Dialog>

            { isloading ? <SuspenseLoader/> : 
            <Dialog 
              open={openDisableCity} 
              onClose={handleCloseDisableCity}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to disabled this City ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDisableCity}>Cancel</Button>
                    <Button onClick={handleDisableCity} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            }
            { isloading ? <SuspenseLoader/> : 
            <Dialog 
              open={openEnableCity} 
              onClose={handleCloseEnableCity}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to Enable this City ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEnableCity}>Cancel</Button>
                    <Button onClick={handleEnableCity} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            }

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>latitude</TableCell>
                            <TableCell>longitude</TableCell>
                            <TableCell align="center">Edit</TableCell>
                            <TableCell align="center">Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        
                        {
                            fbCities.map((city) => {
                                return (
                                    <TableRow key={city.id} style={{background:!city.enabled?"#00000022":"#00000000"}}>
                                    
                                        <TableCell>
                                            <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                            >
                                                {city.id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                            >
                                                {city.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                            >
                                                {city?.center?._lat}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                            >
                                                {city?.center?._long}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button
                                            color="info"
                                            onClick={() => {
                                                handleEditClick(city);
                                            }}
                                            >
                                            Edit
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                        {city.enabled&&<Button
                                            color="info"
                                            onClick={() => {
                                                handleClickOpenDisableCity();
                                                setId(city.id)
                                            }}
                                            >
                                            Disable
                                            </Button>
                                            }
                                            {!city.enabled&&<Button
                                            color="info"
                                            onClick={() => {
                                                handleClickOpenEnableCity();
                                                setId(city.id)
                                            }}
                                            >
                                            Enable
                                            </Button>
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    )
}

export default TableData;