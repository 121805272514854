import { Button, FormControl, InputLabel, MenuItem, Select, Typography,  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText, } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from 'react';
import { collection, doc, getFirestore, updateDoc, onSnapshot, arrayUnion, DocumentReference, query, where, documentId, CollectionReference } from "firebase/firestore";
import { app } from "src/firebase";
import SuspenseLoader from "src/components/SuspenseLoader";
import { SubServiceConverter } from "src/firebase/firestore/convertors/subServices.convertor";
import { ServiceConverter } from "src/firebase/firestore/convertors/services.convertor";
import axios, { AxiosError } from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { API_URL } from "src/constants";

const firestore = getFirestore(app);
const servicesCollection = collection(firestore, 'services').withConverter(
  new ServiceConverter()
);
const subServicesCollection = collection(firestore, 'sub_services').withConverter(
  new SubServiceConverter()
);
type handleClose = {
  handleClose:  () => void;
  providerSubServices: string[];
  id: any
}

function Add(props: handleClose) {
  const [fbServices, setFbServices] = useState([]);
  const [service, setService] = useState<string>('');
  const [filteredSubServices, setFilteredSubServices] = useState([]);
  const [fbSubservices, setFbSubservices] = useState([]);
  const [fbSubservicesfi, setFbSubservicesFi] = useState([]);
  const [fbSubservicesObject, setFbSubservicesObject] = useState({});
  const [test, setTest] = useState<any>();
  const [subService, setSubService] = useState<string>();
  const [providerSubServices, setProviderSubService] = useState<any[]>(props.providerSubServices);
  const [id, setId] = useState(props.id);
  const [deleteSubServiceId, setDeleteSubServiceId] = useState<string>();
  const [providersubserv, setProviderSubserv] = useState([])
  const [openDeleteSubserviceDialog, setOpenDeleteSubserviceDialog] = useState<boolean>(false)
  const [isloading, setIsLoading] = useState<boolean>(false);
  
  const subservcesId: string[] = providerSubServices?.map(p => p.slice(13))
  useEffect(() => {
    const getServices = async (): Promise<any> => {
      onSnapshot(servicesCollection, (d) => {
        setFbServices(d.docs.map(d => d.data()));
      });
    };
    getServices();
    const getSubServices = async (): Promise<any> => {
      onSnapshot(subServicesCollection, (d) => {
        setFbSubservicesFi(d.docs.map((d) => {return {...d.data(),path: d.ref.path}}));
      });
    };
    getSubServices();

    const getSubservicesArray = async (): Promise<any> => {
      let q = query(subServicesCollection, where(documentId(), 'not-in', subservcesId))
      onSnapshot(q, (d) => {
        setFbSubservices(d.docs);
        setTest(d.docs);
      });
    };
    getSubservicesArray();

    const getSubServicesObject = async(): Promise<any> => {
      onSnapshot(subServicesCollection, async(d) => {
        const subServices = {};
        d.docs.forEach(d => {
          subServices[d.ref.path] = d.data();
        });
        setFbSubservicesObject(subServices);
      })
    }
    getSubServicesObject();
  }, [])

  useEffect(() => {
    showSubService()
  },[])
  
  const handleCloseDeleteSubserviceDialog = () => {
    setOpenDeleteSubserviceDialog(false);
  }
  const handleOpenDeleteSubserviceDialog = () => {
    setOpenDeleteSubserviceDialog(true);
  }

  const showSubService = async () => {      
    try {
      await axios.get(`${API_URL}/users/${id}/services`).then(res => {
        setProviderSubserv(res.data.services);
      })
    } catch (e) {
      
      if(e instanceof AxiosError){
        new Noty({
          type: 'error',
          text: e.response?.data.message,
          timeout: 3000,
          theme:'nest',
          progressBar: false,
          layout: 'topRight',
        }).show();
      }
    }
  }
  const addSubService = async () => {
    if(!subService)
      return;
    setIsLoading(true);
    try {
      await axios.put(`${API_URL}/users/${id}/services`,{subService: subService}).then(res => {
        showSubService()
      })
    } catch (e) {
      if(e instanceof AxiosError){
        new Noty({
          type: 'error',
          text: e.response?.data.message,
          timeout: 3000,
          theme:'nest',
          progressBar: false,
          layout: 'topRight',
        }).show();
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handleDeleteSubservice = async () => {
    setIsLoading(true);
    if(deleteSubServiceId){
      try {
        await axios.delete(`${API_URL}/users/${id}/services`,{
          data : {
            subService: deleteSubServiceId
          }
        }).then(res => {
          showSubService()
        })
      } catch (e) {
        if(e instanceof AxiosError){
          new Noty({
            type: 'error',
            text: e.response?.data.message,
            timeout: 3000,
            theme:'nest',
            progressBar: false,
            layout: 'topRight',
          }).show();
        }
      } finally {
        setIsLoading(false);
        handleCloseDeleteSubserviceDialog();
      }
    } else {
      setIsLoading(false);
    }
    
  }

  const handleChange = (e) => {
    setService(e.target.value)
    const filteredServices = fbSubservicesfi.filter((sub) => (sub.service_parent as DocumentReference)?.id ==  e.target.value);
    setFilteredSubServices(filteredServices);
  }

  return (
    isloading ? <SuspenseLoader/> : 
    <>
      <Box
        bgcolor="info"
        borderRadius="lg"
        mx={1}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <Typography  variant="h3" fontWeight="bold" color="info" mt={2}>
           Add SubService
        </Typography>
      </Box>
      <Dialog
        open={openDeleteSubserviceDialog}
        onClose={handleCloseDeleteSubserviceDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isloading ? <SuspenseLoader/> : 
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this service?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteSubserviceDialog}>Cancel</Button>
              <Button onClick={handleDeleteSubservice} autoFocus> 
                Delete Service
              </Button>
            </DialogActions>
          </>
        }
      </Dialog>
      <Box mb={3} width='40vh'>
          <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Services</InputLabel>
              <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={service}
                  label="Service"
                  onChange={(e) => handleChange(e)}
                  sx={{ p: 0, my: 1 }}
              >
                {
                  fbServices.map((s) => {
                    return<MenuItem key={s?.id} value={s?.id}> {s?.name} </MenuItem>
                  })
                }
              </Select>
          </FormControl>
      </Box>
      <Box py={3} px={3} height='40vh' width='40vh'>
        <FormControl fullWidth>
            <InputLabel >Sub Service</InputLabel>
            <Select 
            id="demo-simple-select"
            value={subService}
            label="SubService"
            onChange={(e) =>setSubService(e.target.value)}
            >
            {
              filteredSubServices.map((sub) => {
                return <MenuItem key={sub?.id} value={sub?.id}>{sub.service_name}</MenuItem>
              })
            }
            </Select>
        </FormControl>
        <Box mt={3}>
          <Button color="info" fullWidth onClick={addSubService} >Add</Button>
        </Box>
        <Box py={3} textAlign='center'>
          {
            providersubserv.map((sub) => {return<h2 style={{marginBottom: '15px'}} key={sub}>{fbSubservicesObject[sub]?.service_name} <DeleteIcon sx={{cursor: 'pointer'}} onClick={()=> {setDeleteSubServiceId(fbSubservicesObject[sub]?.id); handleOpenDeleteSubserviceDialog()}}/></h2>})
          }
        </Box>
      </Box>
      
    </>
  )
}

export default Add;