import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = [
    {
        id: number;
        city: string;
        latitude: number,
        longitude: number,
    }
]
type CityAction = {
    id: number;
    city: string;
    latitude: number,
    longitude: number,
}



const initialState: InitialState = [
  {
    id: 100,
    city: "misrata",
    latitude: 12.2121,
    longitude: 32.23232,
  },
];

export const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    addCity: (state, action: PayloadAction<CityAction>) => {
      state.push(action.payload);
    },
    deleteCity: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      if (id == null || id === undefined) {
        return;
      }
      const index = state.findIndex((d) => d.id === id);
      state.splice(index, 1);
    },
  },
});

export const { addCity, deleteCity } = citySlice.actions;

export default citySlice.reducer;