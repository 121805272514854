import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  CardContent,
  Grid,
  IconButton
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { ChangeEvent, useEffect, useState } from 'react';
import MakeProvider from './MakeProvider';
import SuspenseLoader from 'src/components/SuspenseLoader';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import axios, { AxiosError } from 'axios';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useSearchParams } from 'react-router-dom';
// import SearchBar from 'src/components/SearchBar';
import Noty from 'noty';
import AddNewUserComponent from './Add';
import { API_URL } from 'src/constants';
import { GetCities } from '../services/cities.service';
import { City } from 'src/firebase/firestore/city.type';
import { GetAreas } from '../services/areas.service';
import { Zone } from 'src/firebase/firestore/zone.type';
import { DocumentReference } from 'firebase/firestore';
import ClearIcon from '@mui/icons-material/Clear';


let currentUsers = null;

function DataTable() {
  const [searchParams] = useSearchParams();
  const [fbUsersAxios, setFbUsersAxios] = useState<any>({});
  const [fbCities, setCities] = useState<City[]>([]);
  const [fbAreas, setAreas] = useState<Zone[]>([]);
  const [areasFiltered, setAreasFiltered] = useState<Zone[]>([]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openMakeProvider, setOpenMakeProvider] = useState<boolean>(false);
  const [makeProviderUid, setMakeProviderUid] = useState<string>(null);
  const [Id, setId] = useState<string>();
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(
    Number(searchParams.get('limit')) > 0
      ? Number(searchParams.get('limit'))
      : 10
  );
  const [page, setPage] = useState(Number(searchParams.get('page')));
  const [cityId, setCityId] = useState('evx02ojjFpApPVE5ZzkN');
  const [areaId, setAreaId] = useState(searchParams.get('area'));


  const getUsersList = async (page: number, limit: number, city: string, area: string): Promise<any> => {
    try {
      blur();
      const params: any = { limit: limit, page: page };
      if(city)
      params.city = city;
      if(area){
        params.area = area;
        delete params.city;
      }
      await axios
        .get(`${API_URL}/users`, { params:  params})
        .then((res) => {
          setFbUsersAxios(res.data);
        });
    } catch (e) {
      if (e instanceof AxiosError) {
        new Noty({
          type: 'error',
          text: e.response?.data.message,
          timeout: 3000,
          theme: 'nest',
          progressBar: false,
          layout: 'topRight'
        }).show();
      }
    }
  };

  
  useEffect(()=> {
    updateCities();
    updateAreas();
  }, []);

  useEffect(()=> {
    console.log('page or limit changed');
      setIsLoading(true);
      getUsersList(page, limit, cityId, areaId).then(()=>setIsLoading(false)).catch((e)=>{
      console.log('error getting users',e)
      setIsLoading(false);});
  }, [page,limit, cityId, areaId]);

  useEffect(() => {
    filterAreas()
  }, [cityId])
  

  const handleChangeSelectCity = (e,v) => {
    setCityId(v.props.value);
    setAreaId(null);
  }
  const handleChangeSelectArea = (e,v) => {
    setAreaId(v.props.value); 
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleClickOpenMakeProvider = () => {
    setOpenMakeProvider(true);
  };

  const handleClose: () => void = () => {
    setOpen(false);
    currentUsers = null;
    setPage(page);
    getUsersList(page, limit, cityId, areaId);
  };
  const handleCloseDelete: () => void = async () => {
    setOpenDelete(false);
    getUsersList(page, limit, cityId, areaId);
  };
  const handleCloseMakeProvider: () => void = async () => {
    setOpenMakeProvider(false);
    getUsersList(page, limit, cityId, areaId);
  };

  const handleEditClick = (user: any) => {
    currentUsers = user;
    setOpen(true);
  };

  const handleDeleteUser = async () => {
    try {
      setIsLoading(true);
      if (Id) {
        await axios.delete(`${API_URL}/users/${Id}`).then((res) => {
          getUsersList(page, limit, cityId, areaId);
          new Noty({
            type: 'success',
            text: res.data.message,
            theme: 'nest',
            progressBar: true,
            layout: 'topRight',
            timeout: 3000
          }).show();
        });
      }
      setOpenDelete(false);
    } catch (e) {
      if (e instanceof AxiosError) {
        new Noty({
          type: 'error',
          text: e.response?.data.message,
          timeout: 3000,
          theme: 'nest',
          progressBar: false,
          layout: 'topRight'
        }).show();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUserKeepData = async () => {
    try {
      setIsLoading(true);
      if (Id) {
        await axios.delete(`${API_URL}/users/${Id}/keep-data`).then((res) => {
          getUsersList(page, limit, cityId, areaId);
          new Noty({
            type: 'success',
            text: res.data.message,
            theme: 'nest',
            progressBar: true,
            layout: 'topRight',
            timeout: 3000
          }).show();
        });
      }
      setOpenDelete(false);
    } catch (e) {
      if (e instanceof AxiosError) {
        new Noty({
          type: 'error',
          text: e.response?.data.message,
          timeout: 3000,
          theme: 'nest',
          progressBar: false,
          layout: 'topRight'
        }).show();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const submitSearch = (event) => {
    event.preventDefault();
  };

  async function updateCities() {
    const cities = await GetCities();
    setCities(cities);
  }
  async function updateAreas() {
    const areas = await GetAreas();
    setAreas(areas);
  }

  async function handlePageChange(event: any, page: number): Promise<void> {
    setPage(page);
    sessionStorage.
    history.pushState(
      { page: page, limit: limit },
      '',
      `?page=${page}&limit=${limit}`
    );
  }

  async function handleLimitChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): Promise<void> {
    try {
      const limit = parseInt(event.target.value, 10);
      history.pushState(
        { page: page, limit: limit },
        '',
        `?page=${page}&limit=${limit}`
      );
      setLimit(limit);
    } catch {
    } finally {
    }
  }

 const filterAreas = async () => {
  const allAreas = await GetAreas();
   if(cityId) {
    const areasFiltered = allAreas.filter((area) => ((area.cityId == cityId)));
    setAreasFiltered(areasFiltered);
  } else {
    setAreasFiltered(allAreas);
  }
 }  
  return (
    <>
      <Card
        sx={{ backgroundColor: '#ffffff', border: '0', marginBottom: '5px' }}
      >
        <form onSubmit={submitSearch}>
          <TextField
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder="Search..."
            size="medium"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              )
            }}
          />
        </form>
      </Card>
      {/* <SearchBar submitSearch={submitSearch} setSearch={search} /> */}
      <Card>
        <CardHeader
          action={
            <Box style={{display: 'flex', flexDirection: 'row-reverse'}}>
              <Button
                sx={{ mt: { xs: 2, md: 0, lg: 1.1 }, ml: 3 ,height: '50px' }}
                variant="contained"
                startIcon={<AddTwoToneIcon fontSize="small" />}
                onClick={handleClickOpen}
              >
                Add Users
              </Button>
            </Box>
            
          }
          title="Users Table"
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={6} lg={3}>
              <FormControl sx={{ m: 1, width: '100%' }}>
                  <InputLabel id="demo-multiple-name-label">
                    Select City
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={cityId}
                    input={<OutlinedInput label="Select City" />}
                    onChange={(e, v) => handleChangeSelectCity(e, v)}
                  >
                    {Object.keys(fbCities).map((k) => {
                      return (
                        <MenuItem key={fbCities[k]?.id} value={fbCities[k]?.id}>
                          {' '}
                          {fbCities[k]?.name}{' '}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} lg={3} >
              <FormControl sx={{ m: 1, width: '100%' }}>
                  <InputLabel id="demo-multiple-name-label">
                    Select Area
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={areaId}
                    input={<OutlinedInput label="Select City" />}
                    onChange={(e, v) => handleChangeSelectArea(e, v)}
                    endAdornment={<IconButton sx={{display: areaId? "": "none"}} onClick={() => setAreaId(null)}><ClearIcon/></IconButton>}
                  >
                    
                    {
                      
                    Object.keys(areasFiltered).map((k) => {
                      return (
                        <MenuItem key={areasFiltered[k]?.id} value={areasFiltered[k]?.id}>
                          {' '}
                          {areasFiltered[k]?.name}{' '}
                        </MenuItem>
                      );
                    })
                    }
                  </Select>
                </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <AddNewUserComponent
              handleClose={handleClose}
              users={currentUsers}
              makeProvider={false}
            />
          </DialogContent>
        </Dialog>

        <Dialog open={openMakeProvider} onClose={handleCloseMakeProvider}>
          <DialogContent>
            <MakeProvider
              handleClose={handleCloseMakeProvider}
              uid={makeProviderUid}
            />
          </DialogContent>
        </Dialog>

        {isloading ? (
          <SuspenseLoader />
        ) : (
          <Dialog
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDelete}>Cancel</Button>
              <Button onClick={handleDeleteUserKeepData} autoFocus>
                Delete & Keep Data
              </Button>
              <Button onClick={handleDeleteUser}>Delete & Remove Data</Button>
            </DialogActions>
          </Dialog>
        )}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>icon</TableCell>
                <TableCell size="small">Name</TableCell>
                <TableCell size="small">Phone</TableCell>
                <TableCell size="small">Created Time</TableCell>
                <TableCell size="small">City</TableCell>
                <TableCell size="small" align="center">
                  Make Provider
                </TableCell>
                <TableCell size="small" align="center">
                  Edit
                </TableCell>
                <TableCell size="small" align="center">
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fbUsersAxios?.users?.map((user) => {
                return (
                  <TableRow
                    key={user.id}
                    sx={
                      user.locked
                        ? { backgroundColor: '#777' }
                        : { backgroundColor: 'white' }
                    }
                  >
                    <TableCell sx={{ position: 'relative' }}>
                      <img
                        src={
                          user.photoURL
                            ? user.photoURL
                            : '/static/images/avatars/6.png'
                        }
                        alt="provider Image"
                        height={64}
                        width={64}
                      />
                      {user.verification && (
                        <VerifiedIcon
                          color="primary"
                          sx={{ position: 'static', marginLeft: '-30px' }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {user?.displayName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {user?.phoneNumber}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {new Date(user?.creationDate).toLocaleString()}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {(fbCities.find((city) => city?.path == user?.city)
                          ?.name ?? 'not set') +
                          ', ' +
                          (fbAreas.find((area) => area?.path == user?.area)
                            ?.name ?? 'not set')}
                      </Typography>
                    </TableCell>
                    {
                      <>
                        {
                          <TableCell align="center">
                            <Button
                              color="info"
                              onClick={() => {
                                setMakeProviderUid(user.uid);
                                handleClickOpenMakeProvider();
                              }}
                              disabled={
                                user?.isProvider ||
                                user?.type == 'guest' ||
                                user?.type == 'system' ||
                                (user.locked && true)
                              }
                            >
                              Make Provider
                            </Button>
                          </TableCell>
                        }

                        <TableCell align="center">
                          <Button
                            color="info"
                            onClick={() => {
                              handleEditClick(user);
                            }}
                            disabled={
                              user?.type == 'guest' ||
                              (user?.type == 'system' && true)
                            }
                          >
                            Edit
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            color="info"
                            onClick={() => {
                              setId(user.uid);
                              handleClickOpenDelete();
                            }}
                            disabled={
                              user?.type == 'guest' ||
                              (user?.type == 'system' && true)
                            }
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </>
                      // )
                    }
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component="div"
            count={10000} //TODO: Get total from search engine
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 30]}
          />
        </Box>
      </Card>
    </>
  );
}

export default DataTable;
