import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from 'react';
import { collection, doc, getFirestore, updateDoc, onSnapshot, arrayUnion, QueryDocumentSnapshot, DocumentReference, query, where, documentId } from "firebase/firestore";
import { app } from "src/firebase";
import SuspenseLoader from "src/components/SuspenseLoader";
import { SubServiceConverter } from "src/firebase/firestore/convertors/subServices.convertor";
import { SubService } from "src/firebase/firestore/subService.type";
import { ServiceConverter } from "src/firebase/firestore/convertors/services.convertor";
import { PopularServices } from "src/firebase/firestore/popularServices.type";
import { PopularServicesConverter } from "src/firebase/firestore/convertors/PopularServices.converter";

const firestore = getFirestore(app);
const servicesCollection = collection(firestore, 'services').withConverter(
  new ServiceConverter()
);
const popularServicesCollection = collection(firestore,'popular_services').withConverter(new PopularServicesConverter());
const subServicesCollection = collection(firestore, 'sub_services').withConverter(
  new SubServiceConverter()
);
type handleClose = {
  handleClose:  () => void;
  id: any
}

function Add(props: handleClose) {
  const [fbServices, setFbServices] = useState([]);
  const [service, setService] = useState<string>('');
  const [filteredSubServices, setFilteredSubServices] = useState([]);
  const [fbSubservices, setFbSubservices] = useState([]);
  const [fbSubservicesfi, setFbSubservicesFi] = useState([]);
  const [fbSubservicesObject, setFbSubservicesObject] = useState({});
  const [test, setTest] = useState<any>();
  const [subService, setSubService] = useState<string>();
  // const [providerSubServices, setProviderSubService] = useState<string[]>(props.providerSubServices);
  const [id, setId] = useState(props.id);
  const [isloading, setIsLoading] = useState<boolean>(false);
  
  // const subservcesId: string[] = providerSubServices?.map(p => p.slice(13))
  useEffect(() => {
    const getServices = async (): Promise<any> => {
      onSnapshot(servicesCollection, (d) => {
        setFbServices(d.docs.map(d => d.data()));
      });
    };
    getServices();

    const getSubServices = async (): Promise<any> => {
      onSnapshot(subServicesCollection, (d) => {
        setFbSubservicesFi(d.docs.map((d) => {return {...d.data(),path: d.ref.path}}));
      });
    };
    getSubServices();

    const getSubServicesObject = async(): Promise<any> => {
      onSnapshot(subServicesCollection, async(d) => {
        const subServices = {};
        d.docs.forEach(d => {
          subServices[d.ref.path] = d.data();
        });
        setFbSubservicesObject(subServices);
      })
    }
    getSubServicesObject();
  }, [])

  const addSubService = async () => {
    if(!subService)
      return;
    const popularServicesModel: PopularServices = {
      service: doc(firestore, `${subService}`)
    }
    await updateDoc(doc(popularServicesCollection, id),popularServicesModel)
    props.handleClose();
  }
  const handleChange = (e) => {
    setService(e.target.value)
    const filteredServices = fbSubservicesfi.filter((sub) => (sub.service_parent as DocumentReference)?.id ==  e.target.value);
    setFilteredSubServices(filteredServices);
  }

  return (
    isloading ? <SuspenseLoader/> : 
    <>
      <Box
        bgcolor="info"
        borderRadius="lg"
        mx={1}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <Typography  variant="h3" fontWeight="bold" color="info" mt={2}>
           Add SubService
        </Typography>
      </Box>
      <Box mb={3}>
          <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Services</InputLabel>
              <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={service}
                  label="Service"
                  onChange={(e) => handleChange(e)}
                  sx={{ p: 0, my: 1 }}
              >
                {
                  fbServices.map((s) => {
                    return<MenuItem key={s?.id} value={s?.id}> {s?.name} </MenuItem>
                  })
                }
              </Select>
          </FormControl>
      </Box>
      <Box py={3} px={3} height='25vh' width='35vh'>
        <FormControl fullWidth>
            <InputLabel >Sub Service</InputLabel>
            <Select 
            id="demo-simple-select"
            value={subService}
            label="SubService"
            onChange={(e) =>setSubService(e.target.value)}
            >
            {
              filteredSubServices.map((sub) => {
                return <MenuItem key={sub?.id} value={sub?.path}>{sub.service_name}</MenuItem>
              })
            }
            </Select>
        </FormControl>
      </Box>
      <Box mb={1}>
        <Button color="info" type="submit" fullWidth onClick={addSubService} >Add</Button>
      </Box>
    </>
  )
}

export default Add;