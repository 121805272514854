import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from 'react';
import { collection, getFirestore, onSnapshot } from "firebase/firestore";
import { app } from "src/firebase";
import SuspenseLoader from "src/components/SuspenseLoader";
import { PackagesConverter } from "src/firebase/firestore/convertors/packages.convertor";
import axios, { AxiosError } from "axios";
import { getAuth } from "firebase/auth";
import { API_URL } from "src/constants";
const auth = getAuth(app);
const firestore = getFirestore(app);
const packagesCollection = collection(firestore,'packages').withConverter(new PackagesConverter());

type handleClose = {
    handleClose:  () => void;
    endSub: any;
    id: any
    closeEditMenu: () => void;
}

function Add(props: handleClose) {
    const [fbPackages, setFbPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState<string>("");
    const [id, setId] = useState(props.id);
    const [updatePackage, setUpdatePackage] = useState<Date>();
    const [isloading, setIsLoading] = useState<boolean>(false);
  
  useEffect(() => {
    const getPackages = async (): Promise<any> => {
        onSnapshot(packagesCollection, (d) => {
        setFbPackages(d.docs.map(d => {return {...d.data(),id:d.id}}));
        });
    };
    getPackages();
  }, [])

  const addPackage = async () => {
    setIsLoading(true)
    try {
      await axios.put(`${API_URL}/add-subscription`, {
        userId: id,
        packageId: selectedPackage
      })
      .then(res => {
        props.handleClose();
        new Noty({type: 'success', text: res.data.message, theme:'nest', progressBar: true,layout: 'topRight', timeout: 3000 }).show();
      })
    } catch(e) {
      if(e instanceof AxiosError){
        new Noty({
          type: 'error',
          text: e.response?.data.message,
          timeout: 3000,
          theme:'nest',
          progressBar: false,
          layout: 'topRight',
        }).show();
      }
    }finally {
      setIsLoading(false);
      props.closeEditMenu();
    }
    
  }
  const handleChange = (e) => {
    var date = new Date().getTime();
    setSelectedPackage(e.target.value);
    const packageTime = fbPackages.find(p=>p.id==e.target.value)?.time??0;
    if(props.endSub){
      if (date > props.endSub) {
        setUpdatePackage(new Date((date + packageTime)));
      }else {
        setUpdatePackage(new Date(props.endSub + packageTime));
      }
    }else {
      setUpdatePackage(new Date((date + packageTime)));
    }
  };

  return (
    isloading ? <SuspenseLoader/> : 
    <>
      <Box
        bgcolor="info"
        borderRadius="lg"
        mx={1}
        mt={-1}
        p={2}
        textAlign="center">
        <Typography  variant="h3" fontWeight="bold" color="info" mt={2}>
           Add Package
        </Typography>
      </Box>
      <Box py={3} px={3} height='40vh' width='40vh'>
        <FormControl fullWidth>
            <InputLabel >Packages</InputLabel>
            <Select
            id="demo-simple-select"
            value={selectedPackage}
            label="Package"
            onChange={handleChange}
            >
            {
              fbPackages.map((pa) => {
                return <MenuItem key={pa.id} value={pa.id}>{pa.name} -  {pa.price}</MenuItem>
              })
            }
            </Select>
        </FormControl>
        <Box mt={15}>
          Your Subscription will end : {updatePackage?.toLocaleDateString()}
        </Box>
      </Box>
      <Box mb={1}>
        <Button color="info" type="submit" fullWidth onClick={addPackage} >Add</Button>
      </Box>
    </>
  )
}

export default Add;