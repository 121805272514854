import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { app } from '../../../firebase';
import { ZoneConverter } from 'src/firebase/firestore/convertors/zone.converter';
import { Zone } from 'src/firebase/firestore/zone.type';

const firestore = getFirestore(app);
const zonesCollection = collection(firestore, 'city_areas').withConverter(
    new ZoneConverter()
  );

export async function GetAreas(): Promise<Zone[]> {


  if(!sessionStorage.getItem('Areas')){
    const areas = (await getDocs<Zone>(zonesCollection)).docs.map(c=>c.data());    
    sessionStorage.setItem('Areas',JSON.stringify(areas))
    return areas;
  }
  const cacheAreas: Zone[] = JSON.parse( sessionStorage.getItem('Areas'));
  return cacheAreas;
}
