import firebase from 'firebase/compat/app';
const firebaseConfig = {
  apiKey: "AIzaSyANeGA8fkHTLrOV5H1wntKrM5wa9D_V5AU",
  authDomain: "dazzling-rain-358710.firebaseapp.com",
  projectId: "dazzling-rain-358710",
  storageBucket: "dazzling-rain-358710.appspot.com",
  messagingSenderId: "162225102220",
  appId: "1:162225102220:web:3bdb7eacc3bd6801b44aa9",
  measurementId: "G-7QPQZLFWSV"
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);