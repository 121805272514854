import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SetOptions, SnapshotOptions } from "firebase/firestore";
import { Reports } from "../reports.type";

export class ReportsConverter implements FirestoreDataConverter<Reports>{
    toFirestore(modelObject: any): DocumentData;
    toFirestore(modelObject: any, options: SetOptions): DocumentData;
    toFirestore(modelObject: Reports, options?: unknown): import("@firebase/firestore").DocumentData {
        return { ...modelObject };
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): Reports 
    {
        const data = snapshot.data();
        console.log(data.creationDate);
        const reports: Reports = {
            id: snapshot.id,
            message: data.message,
            creationDate: data.creationDate,
            reporter_id: data.reporter_id,
            review_id: data.review_id,
        };

        return reports;
    }

}