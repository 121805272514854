import { useState, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import PropTypes from "prop-types";
import "./index.css";

const mapStyles = [
  {
    featureType: "poi.business",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
];

type LatLong = {
    markerLat: number;
    markerLng: number;
    onChange: ({}) => void;
    center: {} ;
}

function Map(props: LatLong) {
  // eslint-disable-next-line react/prop-types
  const { markerLat, markerLng } = props;
  const [latitude, setLatitude] = useState<number>(markerLat);
  const [longitude, setLongitude] = useState<number>(markerLng);
  const [myMap, setMyMap] = useState<any>();
  useEffect(() => {
    setLatitude(markerLat);
    setLongitude(markerLng);
    if (+markerLng && +markerLat)
      myMap?.panTo({ lat: markerLat, lng: markerLng });
});
  const onMapLoaded = (map) => {
    const { center } = props;
    map.setCenter(center ?? { lat: 32.352612, lng: 15.05911 }); // set to misrata
    setMyMap(map);
  };
  const handleClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setLatitude(lat);
    setLongitude(lng);
    myMap?.panTo({lat, lng});
    props.onChange({lat, lng});
  };
  const markerPosition = { lat: latitude, lng: longitude };
  return (
    <GoogleMap
      options={{
        styles: mapStyles,
        streetViewControl: false,
        zoomControl: false,
        mapTypeControl: false,
      }}
      zoom={12}
      mapContainerClassName="map-container"
      onClick={handleClick}
      onLoad={(d) => onMapLoaded(d)}
    >
      {latitude && longitude && <Marker position={markerPosition} />}
    </GoogleMap>
  );
}

Map.propTypes = {
  center: PropTypes.exact({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
};
export default Map;
