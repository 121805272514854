import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect } from 'react';

import { Button, Input, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useForm } from "react-hook-form";

import ErrorMessage from "../error";
import { collection, doc, DocumentReference, getDoc, getDocs, getFirestore, increment, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore";
import { app } from "src/firebase";
import SuspenseLoader from "src/components/SuspenseLoader";
import { getDownloadURL, getStorage } from "firebase/storage";
import { ref, uploadBytes } from 'firebase/storage';
import { v4 } from 'uuid';
import { AdsBannerConverter } from "src/firebase/firestore/convertors/adsBanner.converter";
import { AdsBanner } from "src/firebase/firestore/adsBanner.type";
import Compressor from 'compressorjs';



const firestore = getFirestore(app);
const adsBannerCollection = collection(firestore,'ads_banner').withConverter<AdsBanner>(new AdsBannerConverter());

const storage = getStorage();

type handleClose = {
    handleClose:  () => void;
    adsBanner: any;
}

function Add(props: handleClose) {

  const [id, setId] = useState<string>(props.adsBanner?.id);
  const [order, setOrder] = useState<number>(props.adsBanner?.order);
  const [enabled, setEnabled] = useState<boolean>(props.adsBanner?.enabled);
  const [text, setText] = useState<string>(props.adsBanner?.text);
  const [images, setImages] = useState<any>(props.adsBanner?.image);
  const [isLoading, setIsLoading] = useState(false);

  const [typePackageNum, setTypePackageNum] = useState<number>(props.adsBanner?.data?.type)
  const [typePackageName, setTypePackageName] = useState<string>()
  const [user, setUser] = useState<string>(props.adsBanner?.data?.user)
  const [subService, setSubService] = useState<string>( (props.adsBanner?.data?.sub_service as DocumentReference)?.id)
  const [url, setUrl] = useState<string>(props.adsBanner?.data?.url)
  const [processedImage, setProcessedImage] = useState<File | Blob>();
  const [selectedImage, setSelectedImage] = useState<File>();
  const [fbAdsBanner, setFbAdsBanner] = useState<AdsBanner[]>();

  const TypePackage : Object = {
    1: 'Go ot user profile',
    2: 'Go to Sub services',
    3: 'Go to Url',
    4: 'Image Only'
  }
  
  const onSubmit = async () => {
    setIsLoading(true);
    
    var urlImg ="";
    if(processedImage !== undefined) {
      const imageRef = ref(storage, `cms_uploads/adsBanner/images/${selectedImage.name + v4()}`);
      var upload =  await uploadBytes(imageRef, processedImage);
      urlImg = await getDownloadURL(upload.ref);
    }else {
      console.log('same image')
      urlImg = images;
    }
    const adsBannerModel: AdsBanner = {
      image: urlImg,
      order: order,
      // enabled: !!enabled,
      text: text,
      data: {
        type: typePackageNum,
        user: user ? doc(firestore,`users/${user}`) : null,
        sub_service: subService ? doc(firestore,`sub_services/${subService}`) : null,
        url:  url ?  url : null,
      }
    };
    if (props.adsBanner == undefined) {
      let q = query(adsBannerCollection, where('order', '>=', order));
      (await getDocs(q)).forEach(d=> {
        updateDoc(d.ref,{
          order: increment(1)
        })
      })
    } 
    else if (props.adsBanner.order < order) {
      let q = query(adsBannerCollection, where('order', '>', props.adsBanner.order), where('order', '<=', order));
      (await getDocs(q)).forEach(d=> {
        updateDoc(d.ref,{
          order: increment(-1)
        })
      })
    } else if (props.adsBanner.order > order) {
      let q = query(adsBannerCollection, where('order', '<', props.adsBanner.order), where('order', '>=', order));
      (await getDocs(q)).forEach(d=> {
        updateDoc(d.ref,{
          order: increment(+1)
        })
      })
    }

    if(id ==  undefined) {
      await setDoc(doc(adsBannerCollection), adsBannerModel);
    } else {
      await updateDoc(doc(adsBannerCollection,id),adsBannerModel);
    }
    
    setIsLoading(false);
    props.handleClose();
  };

  const handleChange = (e) => {
    setOrder(+e.target.value)
  }

  const handleSelect = (e,v) => {
    setTypePackageNum(+v.props.value);
    setTypePackageName(v.props.children[1]);
    setUser('');
    setSubService('');
    setUrl('');
  };

  useEffect (() => {
    const orders = async () : Promise<any> => {
      onSnapshot(adsBannerCollection, (d) => {
        setFbAdsBanner(d.docs.map(d => d.data()));
      })
    }
    orders();
  }, [])

  let count = fbAdsBanner ? Object.keys(fbAdsBanner).length : undefined;
  let countt = (count+1).toString()

  const hanldeCheck = () => {
    setEnabled(!enabled);
  }
  // function use Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  
  return (
    isLoading? <SuspenseLoader/> : 
    <>
      <Box
        bgcolor="info"
        borderRadius="lg"
        mx={1}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <Typography  variant="h3" fontWeight="bold" color="info" mt={2}>
          Add Ads Banner
        </Typography>
      </Box>
      <Box  py={3} px={3}>
        <Box component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
        {(processedImage&&<img src={URL.createObjectURL(processedImage)} alt="image" />) || (images&&<img src={images} alt="image" />)}
            <Box mb={3}>
              <input 
              type="file"
              id="image"
              name="image"
              accept='image/png, image/jpg, image/jpeg'
              onChange={(event) => {
                setSelectedImage(event.target.files[0]);
                new Compressor( event.target.files[0],{
                  maxWidth:500,
                  quality: 0.8,
                  success: async (result) => {
                    setProcessedImage(result);
                  }
                })
              }}
              />
            </Box>
            <Box mb={3}>
              <Input
              type="text"
              placeholder={countt}
              value={order}
              {...register("orderValidation", {
                required: "Please Insert Name Of subService",
                pattern: { value: /^[0-9]*$/, message: "invalid Order" },
              })}
              fullWidth
              onChange={(e) => {setOrder(+e.target.value)}}
              
              />
                {errors.orderValidation && <ErrorMessage message={errors.orderValidation.message} />}
            </Box>
            
            <Box mb={3}>
                <Input
                type="text"
                placeholder="Note"
                value={text}
                {...register("textValidation", {
                  pattern: { value: /^[a-z A-Z0-9\u0621-\u064A]*$/, message: "invalid Text" },
                })}
                fullWidth
                onChange={(e) => setText(e.target.value)}
                />
                  {errors.textValidation && <ErrorMessage message={errors.textValidation.message} />}
            </Box>
            <FormControl fullWidth> 
              <InputLabel >Type Of Package</InputLabel>
              <Select
              id="demo-simple-select"
              value={typePackageNum}
              label="Type"
              onChange={(e,v) => handleSelect(e,v)}
              >
              {
                Object.keys(TypePackage).map( k => {
                  return <MenuItem key={TypePackage[k]?.id} value={k}> {TypePackage[k]} </MenuItem>
                })
              }
              </Select>
            </FormControl>
            {
              typePackageNum == 1 ? (
                <Box mb={3} mt={3}>
                  <Input
                  type="text"
                  placeholder="Type Id of User here!"
                  fullWidth
                  onChange={(e) => setUser(e.target.value)}
                  />
                </Box>
              )
              : typePackageNum == 2 ? (
                <Box mb={3} mt={3}>
                  <Input
                  type="text"
                  placeholder="Type Id of SubServices here!"
                  value={subService}
                  fullWidth
                  onChange={(e) => setSubService(e.target.value)}
                  />
                </Box>
              ): typePackageNum == 3 ? (
                <Box mb={3} mt={3}>
                  <Input
                  type="text"
                  placeholder="Type Url here!"
                  value={url}
                  fullWidth
                  onChange={(e) => setUrl(e.target.value)}
                  />
                </Box>
              )
              :  typePackageNum == 4 ? (
               <></>
              )
              :(
                <Box mb={3} mt={3}>
                  <Input
                  readOnly
                  placeholder="Please Select Type Of Ads"
                  fullWidth
                  />
                </Box>
              )
            }
          {/* <Box mb={3}>
            <FormControlLabel label=""  control={<Checkbox onChange={hanldeCheck} checked={enabled} />} />
              Shown 
          </Box> */}
          <Box mb={1}>
              <Button color="info" type="submit" fullWidth >{props.adsBanner? 'Update': 'Add'}</Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Add;
