import { Box, Button, Card, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, Divider, FormControl, InputLabel, Menu, MenuProps, OutlinedInput, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import Add from './Add'
import { ZoneConverter } from "src/firebase/firestore/convertors/zone.converter";
import { app } from '../../../firebase'
import { getFirestore, collection, onSnapshot, doc, updateDoc, query, orderBy, where, documentId } from 'firebase/firestore'
import SuspenseLoader from "src/components/SuspenseLoader";
import { CityConverter } from "src/firebase/firestore/convertors/city.converer";
import MenuItem from "@mui/material/MenuItem";



const firestore = getFirestore(app);
const zonesCollection = collection(firestore,'city_areas').withConverter( new ZoneConverter());;
const citiesCollection = collection(firestore,'cities').withConverter(new CityConverter());;
// const auth = getAuth(app);


let currentZone = null;

function TableData() {

    const [fbZones, setFbZones ] = useState([]);
    const [fbCities, setCities ] = useState({});
    const [open, setOpen] = useState<boolean>(false);
    const [openDisableArea, setOpenDisableArea] = useState<boolean>(false);
    const [openEnableArea, setOpenEnableArea] = useState<boolean>(false);
    const [Id, setId] = useState<string>();
    const [isloading, setIsLoading] = useState<boolean>(false);
    const [selectCity, setSelectCity] = useState('');

    // const [anchorEl, setAnchorEl] = useState <null | HTMLElement>(null);
    // const openSortMenu = Boolean(anchorEl);
    // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleCloseSortMenu = () => {
    //     setAnchorEl(null);
    // };
    // const StyledMenu = styled((props: MenuProps) => (
    //     <Menu
    //       elevation={0}
    //       anchorOrigin={{
    //         vertical: 'bottom',
    //         horizontal: 'right',
    //       }}
    //       transformOrigin={{
    //         vertical: 'top',
    //         horizontal: 'right',
    //       }}
    //       {...props}
    //     />
    //   ))(({ theme }) => ({
    //     '& .MuiPaper-root': {
    //       borderRadius: 6,
    //       marginTop: theme.spacing(1),
    //       minWidth: 180,
    //       color:
    //         theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    //       boxShadow:
    //         'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    //       '& .MuiMenu-list': {
    //         padding: '4px 0',
    //       },
    //       '& .MuiMenuItem-root': {
    //         '& .MuiSvgIcon-root': {
    //           fontSize: 18,
    //           color: theme.palette.text.secondary,
    //           marginRight: theme.spacing(1.5),
    //         },
    //         '&:active': {
    //           backgroundColor: alpha(
    //             theme.palette.primary.main,
    //             theme.palette.action.selectedOpacity,
    //           ),
    //         },
    //       },
    //     },
    //   }));

    useEffect(() => {
        const getCities = async (): Promise<any>=>{
            onSnapshot(citiesCollection,async (d) => {
                const cities = {};
                 d.docs.forEach(d=>{
                    cities[d.id] = d.data();
                });
                setCities(cities); 
            });
         }
         getCities();
        const getZones = async (): Promise<any> => {
          onSnapshot(query(zonesCollection, orderBy('name', 'asc')),(d) => {
            setFbZones(d.docs.map(d=>d.data()));
          });
        }
        getZones();
    }, [])

    useEffect(() => {
        sortAreas();
    }, [selectCity])

    const handleChangeSelectCity = (e,v) => {
        setSelectCity(v.props.value); 
    }

    const sortAreas = () => {
        if (selectCity) {
            console.log('function Sort', selectCity)
            const q = query(zonesCollection, where('city', '==', doc(citiesCollection,selectCity)))
            onSnapshot(query(q, orderBy('name', 'asc')) ,(snapShot) => {
                setFbZones(snapShot.docs.map((d) => d.data()))
            })
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickOpenDisableArea = () => {
        setOpenDisableArea(true);
    };
    const handleClickOpenEnableArea = () => {
        setOpenEnableArea(true);
    };
    const handleEditClick = (zone: any) => {
        currentZone = zone;
        setOpen(true);
    };
    const handleClose: () =>  void = () => {
        setOpen(false);
        currentZone = null;
    };
    const handleCloseDisableArea: () =>  void = async () => {
        setOpenDisableArea(false);
    };
    const handleCloseEnableArea: () =>  void = async () => {
        setOpenEnableArea(false);
    };
    const handleDisableArea =  async ()  => {
        setIsLoading(true)
        // update area record "enabled" = false
        await updateDoc(doc(zonesCollection,Id),{"enabled":false})
        setOpenDisableArea(false);
        setIsLoading(false)
    };
    const handleEnableArea =  async ()  => {
        setIsLoading(true)
        await updateDoc(doc(zonesCollection,Id),{"enabled":true})
        setOpenEnableArea(false);
        setIsLoading(false)
    };


    return (
        <>
            <Box sx={{textAlign: 'right'}} pr={1} pb={1}>
                <Button
                    sx={{ mt: { xs: 2, md: 0 } }}
                    variant="contained"
                    startIcon={<AddTwoToneIcon fontSize="small" />}
                    onClick={handleClickOpen}
                >
                    Add Zone
                </Button>
            </Box>
            <Card>
                <CardHeader 
                action={
                    <Box width={150}>
                        
                        <FormControl sx={{ m: 1, width: 120 }}>
                            <InputLabel id="demo-multiple-name-label">Select City</InputLabel>
                            <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={selectCity}
                            input={<OutlinedInput label="Select City" />}
                            onChange={(e,v)=> handleChangeSelectCity(e,v)}
                            >
                            {
                                Object.keys(fbCities).map(k=>{
                                    return <MenuItem key={fbCities[k]?.id} value={fbCities[k]?.id}> {fbCities[k]?.name} </MenuItem>
                                })
                            }
                            </Select>
                        </FormControl>
                        
                    </Box>
                }
                title="Zones Table" />
                <Divider />
                <Dialog open={open} onClose={handleClose}>
                    <DialogContent>
                        <Add handleClose={handleClose} zone={currentZone} pCities={fbCities}/>
                    </DialogContent>
                </Dialog>
                { isloading ? <SuspenseLoader/> : 
                <Dialog 
                open={openDisableArea} 
                onClose={handleCloseDisableArea}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to disabled this area ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDisableArea}>Cancel</Button>
                        <Button onClick={handleDisableArea} autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                }
                { isloading ? <SuspenseLoader/> : 
                <Dialog 
                open={openEnableArea} 
                onClose={handleCloseEnableArea}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to Enable this area ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseEnableArea}>Cancel</Button>
                        <Button onClick={handleEnableArea} autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                }
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Zone</TableCell>
                                <TableCell>city of zone</TableCell>
                                <TableCell>latitude</TableCell>
                                <TableCell>longitude</TableCell>
                                <TableCell align="center">Edit</TableCell>
                                <TableCell align="center">Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            
                            {
                                fbZones.map((zone) => {
                                    return (
                                        <TableRow key={zone.id} style={{background:!zone.enabled?"#00000022":"#00000000"}}>
                                            <TableCell>
                                                <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                                color="text.primary"
                                                gutterBottom
                                                noWrap
                                                >
                                                {zone.id}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                                color="text.primary"
                                                gutterBottom
                                                noWrap
                                                >
                                                {zone.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                                color="text.primary"
                                                gutterBottom
                                                noWrap
                                                >   
                                                {fbCities[zone.city.id]?.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                                color="text.primary"
                                                gutterBottom
                                                noWrap
                                                >
                                                {zone?.center?._lat}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                                color="text.primary"
                                                gutterBottom
                                                noWrap
                                                >
                                                {zone?.center?._long}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                color="info"
                                                onClick={() => {
                                                    handleEditClick(zone);
                                                }}
                                                >
                                                Edit
                                                </Button>
                                            </TableCell>
                                            <TableCell align="center">
                                            {zone.enabled&&<Button
                                                color="info"
                                                onClick={() => {
                                                    handleClickOpenDisableArea();
                                                    setId(zone.id)
                                                }}
                                                >
                                                Disable
                                                </Button>
                                                }
                                                {!zone.enabled&&<Button
                                                color="info"
                                                onClick={() => {
                                                    handleClickOpenEnableArea();
                                                    setId(zone.id)
                                                }}
                                                >
                                                Enable
                                                </Button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </>
    )
}

export default TableData; 