import { Button, FormControl, Input, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { collection, DocumentReference, getFirestore, orderBy, query, where } from "firebase/firestore";
import { app } from "src/firebase";
import SuspenseLoader from "src/components/SuspenseLoader";
import { onSnapshot } from 'firebase/firestore';
import { ServiceConverter } from 'src/firebase/firestore/convertors/services.convertor';
import { SubServiceConverter } from 'src/firebase/firestore/convertors/subServices.convertor';
import { PackagesConverter } from "src/firebase/firestore/convertors/packages.convertor";
import axios, { AxiosError } from "axios";
import ErrorMessage from "../error";
import { API_URL } from "src/constants";

const firestore = getFirestore(app);
const servicesCollection = collection(firestore, 'services').withConverter(
    new ServiceConverter()
  );
  const subServicesCollection = collection(firestore, 'sub_services').withConverter(
    new SubServiceConverter()
  );
  const packagesCollection = collection(firestore,'packages').withConverter(new PackagesConverter());

  type makeProviderProps = {
    uid: string;
    handleClose:  () => void;
  }

function MakeProvider(props: makeProviderProps) {
  const [fbServices, setFbServices] = useState([]);
  const [fbSubServices, setFbSubServices] = useState([]);
  const [filteredSubServices, setFilteredSubServices] = useState([]);
  const [service, setService] = useState<string>('');
  const [subService, setSubService] = useState('')
  const [fbPackages, setFbPackages] = useState([]);
  const [phoneOne, setPhoneOne] = useState<string>();
  const [phoneTwo, setPhoneTwo] = useState<string>();
  const [userId] = useState<String>(props.uid);
  const [packg, setPackage] = useState(0);
  const [packgId, setPackageId] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  
  const onSubmit = async () => {
    setIsLoading(true);
    const phones: string[] = [];
    if(phoneOne)
      phones.push(phoneOne)
      if(phoneTwo)
      phones.push(phoneTwo)
    try{
      await axios.put(`${API_URL}/users/${userId}/make-provider`, {
        packageId: packgId,
        subService: subService,
        contactInfo: phones 
      }).then(res => {
      props.handleClose();
      new Noty({type: 'success', text: res.data.message, theme:'nest', progressBar: true,layout: 'topRight', timeout: 3000 }).show();
      })
    } catch(e) {
      if(e instanceof AxiosError){
        new Noty({
          type: 'error',
          text: e.response?.data.message,
          timeout: 3000,
          theme:'nest',
          progressBar: false,
          layout: 'topRight',
        }).show();
      }
    } finally {
      setIsLoading(false);
    }
    
  }

  useEffect(() => {
    const getServices = async (): Promise<any> => {
      onSnapshot(servicesCollection, (d) => {
          onSnapshot(servicesCollection, (d) => {
              setFbServices(d.docs.map(d => d.data()));
          });
      });
      };
      getServices();

      const getSubServices = async (): Promise<any> => {
          onSnapshot(subServicesCollection, (d) => {
            setFbSubServices(d.docs.map((d) => d.data()));
          });
        };
        getSubServices();

        const getPackages = async (): Promise<any> => {
          onSnapshot(query(packagesCollection, orderBy('time', 'asc')), (d) => {
            setFbPackages(d.docs);
          });
      };
      getPackages();
  }, [])
  

  // function use Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  // set City dropdown menu
  const handleChange = (e) => {
    setService(e.target.value)
    const filteredServices = fbSubServices.filter((sub) => (sub.service_parent as DocumentReference)?.id ==  e.target.value);
    setFilteredSubServices(filteredServices);
  };
  var date = Number(new Date().getTime());

  return (
    isLoading? <SuspenseLoader/> : 
    <>
      <Box
        bgcolor="info"
        borderRadius="lg"
        mx={1}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <Typography  variant="h3" fontWeight="bold" color="info" mt={2}>
          Add Provider
        </Typography>
      </Box>
      <Box  py={3} px={3}>
        <Box component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
            <Box mb={3}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Services</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={service}
                        label="Service"
                        onChange={(e) => handleChange(e)}
                        sx={{ p: 0, my: 1 }}
                    >
                        {
                            fbServices.map((s) => {
                                return<MenuItem key={s?.id} value={s?.id}> {s?.name} </MenuItem>
                            })
                        }
                        
                        
                    </Select>
                </FormControl>
            </Box>
            <Box mb={3}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Sub Services</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={subService}
                        label="Service"
                        onChange={(e) => setSubService(e.target.value)}
                        sx={{ p: 0, my: 1 }}
                    >
                        {
                            filteredSubServices.map((sub) => {
                                return<MenuItem key={sub?.id} value={sub?.id}> {sub?.service_name} </MenuItem>
                            })
                        }
                        
                        
                    </Select>
                </FormControl>
            </Box>
            <Box mb={3}>
                <Input
                type="text"
                placeholder="Phone One"
                value={phoneOne}
                onChange={(e) => setPhoneOne(e.target.value)}
                fullWidth
                />
                  {errors.phoneValidation && <ErrorMessage message={errors.phoneValidation.message} />}
            </Box>
            <Box mb={3}>
                <Input
                type="text"
                placeholder="Phone Two"
                value={phoneTwo}
                onChange={(e) => setPhoneTwo(e.target.value)}
                fullWidth
                />
                  {errors.phoneValidation && <ErrorMessage message={errors.phoneValidation.message} />}
            </Box>
            <Box py={3} px={3} height='20vh' width='40vh'>
                <FormControl fullWidth>
                    <InputLabel >Packages</InputLabel>
                    <Select
                    id="demo-simple-select"
                    value={packgId}
                    label="Package"
                    onChange={(e) => {
                      const p = fbPackages.find(p=>p.id == e.target.value)?.data();
                      setPackage(p.time); setPackageId(e.target.value as string)
                    }}
                    >
                    {
                    fbPackages.map((pa) => {
                      const data = pa.data();
                      return <MenuItem key={pa.id} value={pa.id}>{data.name} -  {data.price}</MenuItem>
                    })
                    }
                    </Select>
                </FormControl>
            </Box>
            <Box py={3} px={3} height='20vh' width='40vh'>
                <Typography>
                  Your Subscription will end : { packg && new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'numeric',day: 'numeric'}).format((date + packg)) }
                </Typography>
            </Box>
            <Box mb={1}>
                <Button color="info" type="submit" fullWidth >Add</Button>
            </Box>
        </Box>
      </Box>
    </>
  )
}

export default MakeProvider;