import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useEffect, useState } from 'react';
import Add from './Add';

import { app } from '../../../firebase';
import {
  getFirestore,
  collection,
  onSnapshot,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import { ServiceConverter } from 'src/firebase/firestore/convertors/services.convertor';
import SuspenseLoader from 'src/components/SuspenseLoader';

const firestore = getFirestore(app);
const servicesCollection = collection(firestore, 'services').withConverter(
  new ServiceConverter()
);
let currentService = null;

function ServicesTable() {
  const [fbServices, setFbServices] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const [isloading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getServices = async (): Promise<any> => {
      onSnapshot(servicesCollection, (d) => {
        setFbServices(d.docs.map((d) => d.data()));
      });
    };
    getServices();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleClose: () => void = () => {
    setOpen(false);
    currentService = null;
  };
  const handleCloseDelete: () => void = async () => {
    setOpenDelete(false);
  };
  const handleEditClick = (s: any) => {
    currentService = s
    setOpen(true);
  };
  const handleDeleteItem = async () => {
    setIsLoading(true)
    if (deleteId) {
      await deleteDoc(doc(servicesCollection, deleteId.toString()));
    }
    setOpenDelete(false);
    setIsLoading(false)
  };

  return (
    <Card>
      <CardHeader
        action={
          <Box width={150}>
            <Button
              sx={{ mt: { xs: 2, md: 0 } }}
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small" />}
              onClick={handleClickOpen}
            >
              Add Service
            </Button>
          </Box>
        }
        title="Services Table"
      />
      <Divider />
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Add handleClose={handleClose} service={currentService} />
        </DialogContent>
      </Dialog>
      { isloading ? <SuspenseLoader/> :
        <Dialog
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this item ?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseDelete}>Cancel</Button>
            <Button onClick={handleDeleteItem} autoFocus>
                Delete
            </Button>
            </DialogActions>
        </Dialog>
        }
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell size='small'>Name</TableCell>
              <TableCell>Icon</TableCell>
              <TableCell size='small' align="center">Edit</TableCell>
              <TableCell size='small' align="center">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fbServices.map((s) => {
              return (
                <TableRow key={s.id}>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {s.id}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {s.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <img src={s.imageUrl} alt="Service Image" height={128} width={128} />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      color="info"
                      onClick={() => {
                        handleEditClick(s);
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      color="info"
                      onClick={() => {
                        setDeleteId(s.id);
                        handleClickOpenDelete();
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

export default ServicesTable;
