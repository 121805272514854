import {
    Box,
    Card,
    CardHeader,
    Divider,
    TableBody,
    TableCell,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    Typography,
  } from '@mui/material';
import { Container } from '@mui/system';
  
  function City() {
    return (
      <Container maxWidth="lg">
        <Card sx={{my: 2}}>
            <CardHeader action={<Box width={150}></Box>} title="Average time on/off" />
            <Divider />
    
            <TableContainer>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell align='center'>id</TableCell>
                    <TableCell align='center'>City</TableCell>
                    <TableCell align='center'>avg off</TableCell>
                    <TableCell align='center'>avg on</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                <TableRow>
                    <TableCell align='center'>
                    <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                    >
                        1
                    </Typography>
                    </TableCell>
                    <TableCell align='center'>
                    <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                    >
                        مصراتة
                    </Typography>
                    </TableCell>
                    <TableCell align='center'>
                    <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                    >
                        5hr
                    </Typography>
                    </TableCell>
                    <TableCell align='center'>
                    <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                    >
                        19hr
                    </Typography>
                    </TableCell>
                </TableRow>
                </TableBody>
            </Table>
            </TableContainer>
        </Card>
      </Container>
    );
  }
  export default City;
  