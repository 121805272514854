import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Table,
  TableBody,
  FormControlLabel,
  Switch,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useEffect, useState } from 'react';
import { app } from '../../../firebase';
import {
  getFirestore,
  collection,
  onSnapshot,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import { AdsBannerConverter } from 'src/firebase/firestore/convertors/adsBanner.converter';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Add from './Add';

const firestore = getFirestore(app);
const adsBannerCollection = collection(firestore, 'ads_banner').withConverter(
  new AdsBannerConverter()
);


let currentAdsBanner = null;

function DataTable() {
  const [fbAdsBanner, setFbAdsBanner] = useState([]);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState<string>();
  const [isloading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getAdsBanner = async (): Promise<any> => {
      onSnapshot(adsBannerCollection, (d) => {
        setFbAdsBanner(d.docs.map(d => d.data()));
      })
    }
    getAdsBanner();
  }, []
  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose: () =>  void = async () => {
    setOpen(false);
    currentAdsBanner = null;
  };
  const handleEditClick = (ads: any) => {
    currentAdsBanner = ads
    setOpen(true);
  };
  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete: () => void = async () => {
    setOpenDelete(false);
  };
 
  const handleDeleteItem = async () => {
    setIsLoading(true)
    if (id) {
      await deleteDoc(doc(adsBannerCollection, id));
    }
    setOpenDelete(false);
    setIsLoading(false)
  };

  return (
    <>
      <Card>
        <CardHeader
        action={
          <Box width={150}>
            <Button
              sx={{ mt: { xs: 2, md: 0 } }}
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small" />}
              onClick={handleClickOpen}
            >
            Add Ads Banner
          </Button>
        </Box>
      }
        title="Ads Banner Table"/>
        <Divider />
        { isloading ? <SuspenseLoader/> :
          <>
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this item ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDelete}>Cancel</Button>
                <Button onClick={handleDeleteItem} autoFocus>
                    Delete
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Add handleClose={handleClose} adsBanner={currentAdsBanner} />
                </DialogContent>
            </Dialog>
          </>

          }
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Images</TableCell>
                <TableCell size='small'>order</TableCell>
                <TableCell size='small'>Text</TableCell>
                {/* <TableCell size='small'>enabled</TableCell> */}
                <TableCell size='small' align="center">Edit</TableCell>
                <TableCell size='small' align="center">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fbAdsBanner.map((ads) => {
                return (
                  <TableRow key={ads.id}>
                    <TableCell >
                      <img src={ads.image} alt='at' width={128}/>
                    </TableCell>
                    <TableCell>
                      <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                      >
                        {ads.order}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        sx={{wordBreak: 'break-word'}}
                      >
                        {ads?.text}
                      </Typography>
                    </TableCell>
                    {/* <TableCell width={2}>
                      <FormControlLabel label=""  control={<Switch checked={ads.enabled} />} />
                    </TableCell> */}
                    <TableCell width={2} align="center">
                        <Button
                        color="info"
                        onClick={() => {
                            handleEditClick(ads);
                        }}
                        >
                        Edit
                        </Button>
                    </TableCell>
                    <TableCell width={2} align="center">
                      <Button
                        color="info"
                        onClick={() => {
                          setId(ads.id);
                          handleClickOpenDelete();
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}

export default DataTable;
