import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SetOptions, SnapshotOptions } from "firebase/firestore";
import { Zone } from "../zone.type";

export class ZoneConverter implements FirestoreDataConverter<Zone>{
    toFirestore(modelObject: any): DocumentData;
    toFirestore(modelObject: any, options: SetOptions): DocumentData;
    toFirestore(modelObject: Zone, options?: unknown): import("@firebase/firestore").DocumentData {
        delete modelObject.cityId;
        return { ...modelObject };
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): Zone 
    {
        const data = snapshot.data();
        const zone: Zone = {
            name: data.name,
            id: snapshot.id,
            center: data.center,
            city: data.city,
            enabled: !!data.enabled,
            path: snapshot.ref.path,
            cityId: data.city?.id
        };

        return zone;
    }

}