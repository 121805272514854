import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { Button, Input, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useForm } from "react-hook-form";

import ErrorMessage from "../error";
import { collection, doc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import { app } from "src/firebase";
import SuspenseLoader from "src/components/SuspenseLoader";
import { SubService } from "src/firebase/firestore/subService.type";
import { getDownloadURL, getStorage } from "firebase/storage";
import { ref, uploadBytes } from 'firebase/storage';
import { v4 } from 'uuid';
import { SubServiceConverter } from "src/firebase/firestore/convertors/subServices.convertor";
import Compressor from 'compressorjs';
const firestore = getFirestore(app);
const subServicesCollection = collection(firestore,'sub_services').withConverter(new SubServiceConverter());
const storage = getStorage();

type handleClose = {
    handleClose:  () => void;
    subService: any;
    fbServices: any;
}

function Add(props: handleClose) {

  const [id, setId] = useState<string>(props.subService?.id);
  const [serviceName, setServiceName] = useState<string>(props.subService?.service_name);
  const [serviceParent, setServiceParent] = useState<string>(props.subService?.service_parent?.id);
  const [serviceDesc, setServiceDesc] = useState<string>(props.subService?.service_description);
  const [fbServices, setFbServices] = useState<string>(props.fbServices);
  const [selectedImage, setSelectedImage] = useState<File>();
  const [processedImage, setProcessedImage] = useState<File | Blob>();
  const [subUrlImage, setSubUrlImage] = useState<any>(props.subService?.image);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true); 
    try{
      if(processedImage !== undefined) {
        const imageRef = ref(storage, `cms_uploads/sub_services/images/${selectedImage.name + v4()}`);
        var url = await (await uploadBytes(imageRef, processedImage).then((snapshot) => getDownloadURL(snapshot.ref) ));
      }else {
        url = subUrlImage??"https://firebasestorage.googleapis.com/v0/b/dazzling-rain-358710.appspot.com/o/cms_uploads%2Fsub_services%2Fimages%2Fdefault.jpg?alt=media&token=87d6a13d-e0c6-41d2-b97a-e3c018af49a4"
      };

      const subServiceModel: SubService = {
        service_name: serviceName,
        service_description: serviceDesc,
        service_parent: doc(firestore,`services/${serviceParent}`),
        image: url
      };
      if(id ==  undefined) {
        await setDoc(doc(subServicesCollection), subServiceModel);
      } else {
        await updateDoc(doc(subServicesCollection,id),subServiceModel);
      }
      props.handleClose();

    }catch(e) {
      if( e instanceof Error) {
        new Noty({
          type: 'error',
          text: e.message,
          timeout: 3000,
          theme:'nest',
          progressBar: false,
          layout: 'topRight',
        }).show();
      }
    } finally {
      setIsLoading(false);
    }
    
    
  };

  // function use Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

    // set Services dropdown menu
    const handleChange = (e,v) => {
      setServiceParent(v.props.value);
    };

  return (
    isLoading? <SuspenseLoader/> : 
    <>
      <Box
        bgcolor="info"
        borderRadius="lg"
        mx={1}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <Typography  variant="h3" fontWeight="bold" color="info" mt={2}>
          Add Sub Services
        </Typography>
      </Box>
      <Box  py={3} px={3}>
        <Box component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
            <Box mb={3}>
                <Input
                type="text"
                placeholder="Sub Service Name"
                value={serviceName}
                {...register("subServiceNameValidation", {
                  required: "Please Insert Name Of subService",
                  pattern: { value: /^[a-z A-Z0-9\u0621-\u064A]*$/, message: "invalid name" },
                })}
                fullWidth
                onChange={(e) => setServiceName(e.target.value)}
                />
                  {errors.subServiceNameValidation && <ErrorMessage message={errors.subServiceNameValidation.message} />}
            </Box>
            <Box mb={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Services</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={serviceParent}
                  label="Services"
                  onChange={(e,v)=>handleChange(e,v)}
                  sx={{ p: 0, my: 1 }}
                >
                {
                  Object.keys(fbServices).map(k=>{
                    return <MenuItem key={fbServices[k]?.id} value={fbServices[k]?.id}> {fbServices[k]?.name} </MenuItem>
                  })
                }
                
              </Select>
              </FormControl>
            </Box>
            <Box mb={3}>
                <Input
                type="text"
                placeholder="Sub Service Description"
                value={serviceDesc}
                {...register("subServiceDescriptionValidation", {
                  required: "Please Insert Description Of subService",
                  pattern: { value: /^[a-z A-Z0-9\u0621-\u064A]*$/, message: "invalid Description" },
                })}
                fullWidth
                onChange={(e) => setServiceDesc(e.target.value)}
                />
                  {errors.subServiceDescriptionValidation && <ErrorMessage message={errors.subServiceDescriptionValidation.message} />}
            </Box>
            <Box mb={3}>
            <label style={{marginRight: '25px'}}>Choose Image</label>
              <input 
              type="file"
              id="image"
              name="image"
              accept='image/*'
              onChange={(event) => {
                setSelectedImage(event.target.files[0]);
                new Compressor( event.target.files[0],{
                  maxWidth:500,
                  quality: 0.8,
                  success: async (result) => {
                    setProcessedImage(result);
                  }
                })
                
              }}
              />
            </Box>
            <Box mb={1}>
                <Button color="info" type="submit" fullWidth >{props.subService? 'Update': 'Add'}</Button>
            </Box>
        </Box>
      </Box>
    </>
  )
}

export default Add;