import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { app } from '../../../firebase';
import {
  getFirestore,
  collection,
  onSnapshot,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  query,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  DocumentReference,
  where,
  getDoc,
  QuerySnapshot,
  DocumentData,
  Unsubscribe,
} from 'firebase/firestore';
import { PostsConverter } from 'src/firebase/firestore/convertors/posts.converter';
import SuspenseLoader from 'src/components/SuspenseLoader';

import { UsersConverter } from 'src/firebase/firestore/convertors/users.convertor';
import { Data } from '@react-google-maps/api';
import { Users } from 'src/firebase/firestore/users.type';
import { Posts } from 'src/firebase/firestore/posts.type';
import { set } from 'date-fns';

// import SearchBar from 'src/components/SearchBar';

const firestore = getFirestore(app);
const postsCollection = collection(firestore, 'posts').withConverter<Posts>(
  new PostsConverter()
);
const usersCollection = collection(firestore, 'users').withConverter(
  new UsersConverter()
);
// get user by document reference
const getUser = async (userRef: DocumentReference<Users>): Promise<Users> => {
  const user = await getDoc(userRef);
  return user.data();
}
function DataTable() {
  const [fbPosts, setFbPosts] = useState([]);
  // const [fbUsers, setFbUsers] = useState({});
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [id, setId] = useState<string>();
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  let pageUnSubscribe: Unsubscribe = null;
  // let usersSnapshot: Unsubscribe  = null;

  useEffect(() => {
    getPosts({});
    }, []
  );
  const getPosts = async (params: {next?: Posts, before?: Posts}): Promise<any> => {
    if(!!pageUnSubscribe) pageUnSubscribe();

    if(params.next) {
      pageUnSubscribe = onSnapshot(query(postsCollection, orderBy('created_on', 'desc'), limit(10), startAfter(params.next.created_on)), dealWithPostsChanges)
      setPage(page + 1)
    } else if(params.before) {
      pageUnSubscribe = onSnapshot(query(postsCollection, orderBy('created_on', 'desc'), limitToLast(10), endBefore(params.before.created_on)), dealWithPostsChanges)
      setPage(page - 1)
    } else {
      pageUnSubscribe = onSnapshot(query(postsCollection, orderBy('created_on', 'desc'), limit(10)), dealWithPostsChanges);
      setPage(1);
    }
  }
  const getUserFromFireStore = async (userRef: DocumentReference): Promise<void> => {
    const userSessionData = sessionStorage.getItem(userRef.path);
    if(!!userSessionData) {
      return;
    }
    const user = await getDoc(userRef.withConverter<Users>(new UsersConverter()));
    if(!user.exists()) {
      sessionStorage.setItem(userRef.path, JSON.stringify({displayName: 'حساب محذوف'}));
      return;
    }
    const data = user.data();
    sessionStorage.setItem(userRef.path, JSON.stringify(data));
  }
  const getUserFromSession = (userRef: DocumentReference<Users>): Users => {
    const userSessionData = sessionStorage.getItem(userRef.path);
    if(!!userSessionData) {
      return JSON.parse(userSessionData) as Users;
    }
    return null;
  }

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete: () => void = async () => {
    setOpenDelete(false);
  };
 
  const handleDeleteItem = async () => {
    setIsLoading(true)
    if (id) {
      await deleteDoc(doc(postsCollection, id));
    }
    setOpenDelete(false);
    setIsLoading(false)
  };
  const handleClickApproved = async (id: string) => {
    var approvedDate = {};
    approvedDate["approval"] = {approved_by: 'admin@sahel.com.ly',approved_on: new Date() };
    await updateDoc(doc(collection(firestore,"posts"), `${id}`), approvedDate)
  }

  return (
    <>
      <Card>
        <CardHeader 
        action={
          <Box >
            <ButtonGroup>
            { 
              <Button disabled={page === 1} style={{margin: '0px'}} onClick={() => getPosts({ before: fbPosts[0] }) }>Previous</Button>
            }

            {
              <Button disabled={fbPosts.length < 10} style={{margin: '0px'}} onClick={() => getPosts({ next: fbPosts[fbPosts.length - 1] })}>Next</Button>
            }
            </ButtonGroup>
          </Box>
        }
         title="Posts Table"/>
        <Divider />
        { isloading ? <SuspenseLoader/> :
          <Dialog
              open={openDelete}
              onClose={handleCloseDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this item ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDelete}>Cancel</Button>
                <Button onClick={handleDeleteItem} autoFocus>
                    Delete
                </Button>
              </DialogActions>
          </Dialog>
          }
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell size='small'>Name</TableCell>
                <TableCell size='small'>Created Post</TableCell>
                <TableCell size='small'>Content</TableCell>
                <TableCell size='small'>Images</TableCell>
                <TableCell size='small' align="center">Approve</TableCell>
                <TableCell size='small' align="center">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fbPosts.map((post) => {
                const user = getUserFromSession(post.user as DocumentReference<Users>);
                return (
                  <TableRow key={post.id}>
                    <TableCell>
                      <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                      >
                      {user?.display_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        fontSize={12}
                      >
                        {new Intl.DateTimeFormat('en-US', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric'
                        }).format((post.created_on.seconds)*1000)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        width='40vh'
                        sx={{wordBreak: 'break-word'}}
                      >
                        {post.content}
                      </Typography>
                    </TableCell>
                    <TableCell >
                      { post.images.map((object, i)=>{
                          return <span key={i} style={{marginLeft: '5px'}}><img src={object} key={i} height={64} width={64}/></span>
                        })
                      }
                    </TableCell>

                    <TableCell align="center">
                      <Button
                        color="info"
                        onClick={() => handleClickApproved(post.id)}
                        disabled={post.approval.approved_by != undefined ? true : false }
                      >
                        approved
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        color="info"
                        onClick={() => {
                          setId(post.id);
                          handleClickOpenDelete();
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );

  async function dealWithPostsChanges(data: QuerySnapshot<Posts>) {
      let items = [];
      for(let i = 0; i < data.docs.length; i++) {
        const postData: Posts = data.docs[i].data();
        items.push({key: data.docs[i].id, ...postData });
        await getUserFromFireStore(postData.user as DocumentReference<Users>);
      }
      setFbPosts(items);
  }
}

export default DataTable;
