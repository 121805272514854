import {
  Button,
  Card,
  Box,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Menu,
  MenuItem,
  CardContent,
  Grid,
  IconButton
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { ChangeEvent, useEffect, useState } from 'react';
import AddPackage from './AddPackage';
import { Unsubscribe } from 'firebase/firestore';
import SuspenseLoader from 'src/components/SuspenseLoader';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { Link } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import AddSubservice from './addSubservice';
import VerifiedIcon from '@mui/icons-material/Verified';
import AddNewUserComponent from '../../Users/Add';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { useSearchParams } from 'react-router-dom';
import EditContactInfo from './EditContactInfo';
import Verify from './Verify';
import EditProfile from './EditProfile';
import { API_URL } from 'src/constants';
import { City } from 'src/firebase/firestore/city.type';
import { Zone } from 'src/firebase/firestore/zone.type';
import { GetCities } from '../../services/cities.service';
import { GetAreas } from '../../services/areas.service';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { GetServices } from '../../services/services.service';
import { Service } from 'src/firebase/firestore/service.type';
import { GetSubServices } from '../../services/sub_services.service';
import { SubService } from 'src/firebase/firestore/subService.type';
import { app } from '../../../../firebase';
import {
  getFirestore,
  collection,
  onSnapshot,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import { SubServiceConverter } from 'src/firebase/firestore/convertors/subServices.convertor';

let currentProvider = null;
let test = null;
const firestore = getFirestore(app);
const subServicesCollection = collection(firestore, 'sub_services').withConverter(
  new SubServiceConverter()
);

function DataTable() {
  const [searchParams] = useSearchParams();
  const [Providers, setProviders] = useState<any>({});
  const [fbSubServices, setFbSubServices] = useState({});
  const [providerSubService, setProviderSubService] = useState<string[]>();
  const [fbCities, setCities] = useState<City[]>([]);
  const [fbAreas, setAreas] = useState<Zone[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [subServices, setSubServices] = useState<SubService[]>([]);
  const [areasFiltered, setAreasFiltered] = useState<Zone[]>([]);
  const [subServicesFiltered, setSubservicesFiltered] = useState<SubService[]>(
    []
  );
  const [endSub, setEndSub] = useState();
  const [id, setId] = useState();
  const [openProfileImage, setOpenProfileImage] = useState<boolean>(false);
  const [openPackage, setOpenPackage] = useState(false);
  const [openSubservice, setOpenSubservice] = useState(false);
  const [openAddProvider, setOpenAddProvider] = useState(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>();
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState(searchParams.get('search'));
  const [limit, setLimit] = useState(
    Number(searchParams.get('limit')) > 0
      ? Number(searchParams.get('limit'))
      : 10
  );
  const [page, setPage] = useState(Number(searchParams.get('page')??1));
  const [currentUser, setCurrentUser] = useState(null);
  const [openContactInfo, setOpenContactInfo] = useState<boolean>(false);
  const [openVerify, setOpenVerify] = useState<boolean>(false);
  const [openCancelVerify, setOpenCancelVerify] = useState<boolean>(false);
  const [openCancelMembership, setOpenCancelMembership] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [cityId, setCityId] = useState('evx02ojjFpApPVE5ZzkN');
  const [areaId, setAreaId] = useState(searchParams.get('area'));
  const [serviceId, setServiceId] = useState('');
  const [resultsCount, setResultsCount] = useState(0);
  const [subserviceId, setSubserviceId] = useState(
    searchParams.get('subservice')
  );

  const openEditMenu = Boolean(anchorEl);
  const handleOpenEditMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    user: any
  ) => {
    setCurrentUser(user);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseEditMenu = () => {
    setAnchorEl(null);
    setCurrentUser(null);
    getProviderAxios(page, limit, cityId, areaId, subserviceId, search);
  };
  let userProvidersSnapshot: Unsubscribe = null;
  useEffect(() => {
    const getSubServices = async (): Promise<any> => {
      onSnapshot(subServicesCollection, async(d) => {
        const subServices = {};
        d.docs.forEach(d => {
          subServices[d.id] = d.data();
        });
        setFbSubServices(subServices);
      })
    };
    getSubServices();
  }, []);

  const getProviderAxios = async (
    page: number,
    limit: number,
    city: string,
    area: string,
    subservice: string,
    search: string
  ): Promise<any> => {
    try {
      blur();
      const params: any = { limit: limit, page: page, q: search?search:undefined };
      if (city) params.city = city;
      if (area) {
        params.area = area;
        delete params.city;
      }
      if (subservice) {
        params.subservice = subservice;
      }
      await axios
        .get(`${API_URL}/providers/active`, { params: params })
        .then((res) => {
          setProviders(res.data);
          setResultsCount(res.data?.found??0);
          new Noty({
            type: 'success',
            text: res.data.message,
            theme: 'nest',
            progressBar: true,
            layout: 'topRight',
            timeout: 3000
          }).show();
        });
    } catch (e) {
      if (e instanceof AxiosError) {
        new Noty({
          type: 'error',
          text: e.response?.data.message,
          timeout: 3000,
          theme: 'nest',
          progressBar: false,
          layout: 'topRight'
        }).show();
      }
    }
  };

  useEffect(() => {
    updateCities();
    updateAreas();
    updateServices();
    updateSubServices();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getProviderAxios(page, limit, cityId, areaId, subserviceId, search)
      .then(() => setIsLoading(false))
      .catch((e) => {
        console.log('error', e);
        setIsLoading(false);
      });
  }, [page, limit, cityId, areaId, subserviceId]);

  useEffect(() => {
    filterAreas();
  }, [cityId]);


  useEffect(() => {
    filterSubServices();
  }, [serviceId]);

  const handleChangeSelectCity = (e, v) => {
    setCityId(v.props.value);
    setAreaId(null);
  };
  const handleChangeSelectArea = (e, v) => {
    setAreaId(v.props.value);
  };

  const handleChangeSelectService = (e, v) => {
    setServiceId(v.props.value);
  };
  const handleChangeSelectSubserbive = (e, v) => {
    setSubserviceId(v.props.value);
  };
  const handleCancelVerify = async () => {
    setIsLoading(true);
    try {
      await axios
        .put(`${API_URL}/users/${currentUser?.uid}/unverify`)
        .then((res) => {
          new Noty({
            type: 'success',
            text: res.data.message,
            theme: 'nest',
            progressBar: true,
            layout: 'topRight',
            timeout: 3000
          }).show();
        });
    } catch (e) {
      if (e instanceof AxiosError) {
        new Noty({
          type: 'error',
          text: e.response?.data?.message,
          timeout: 3000,
          theme: 'nest',
          progressBar: false,
          layout: 'topRight'
        }).show();
      }
    } finally {
      setIsLoading(false);
      handleColseCancelVerify();
      handleCloseEditMenu();
    }
  };

  const handleOpenAddProvider = () => {
    setOpenAddProvider(true);
  };

  const hanldeCloseAddProvider = () => {
    setOpenAddProvider(false);
    setPage(1);
    getProviderAxios(page, limit, cityId, areaId, subserviceId, search);
  };

  const handleClickPackage = () => {
    setOpenPackage(true);
  };
  const handleClosePackage: () => void = () => {
    setOpenPackage(false);
  };

  const handleClickSubservice = () => {
    setOpenSubservice(true);
  };
  const handleCloseSubservice: () => void = () => {
    setOpenSubservice(false);
  };

  const handleCloseProfileImage: () => void = () => {
    setOpenProfileImage(false);
    currentProvider = null;
  };
  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete: () => void = async () => {
    setOpenDelete(false);
  };

  const handleEditPrfileImage = () => {
    currentProvider = currentUser;
    setOpenProfileImage(true);
  };

  const handleOpenContactInfo = () => {
    setOpenContactInfo(true);
    currentProvider = currentUser;
  };

  const handleCloseContactInfo = () => {
    setOpenContactInfo(false);
  };

  const handleOpenVerify = () => {
    setOpenVerify(true);
  };
  const handleCloseVerify = () => {
    setOpenVerify(false);
  };
  const handleOpenCancelVerify = () => {
    setOpenCancelVerify(true);
  };
  const handleColseCancelVerify = () => {
    setOpenCancelVerify(false);
  };

  const handleOpenCancelMembership = () => {
    setOpenCancelMembership(true);
  };
  const handleCloseCancelMembership = () => {
    setOpenCancelMembership(false);
  };

  const submitSearch = async (event) => {
    event.preventDefault();
    setPage(1);
    await getProviderAxios(page, limit, cityId, areaId, subserviceId, search);
  };

  const handleCancelMembership = async () => {
    setIsLoading(true);
    if (currentUser) {
      try {
        await axios
          .delete(`${API_URL}/users/${currentUser.uid}/membership`)
          .then((res) => console.log(res));
      } catch (e) {
        if (e instanceof AxiosError) {
          new Noty({
            type: 'error',
            text: e.response?.data.message,
            timeout: 3000,
            theme: 'nest',
            progressBar: false,
            layout: 'topRight'
          }).show();
        }
      } finally {
        setIsLoading(false);
        handleCloseCancelMembership();
        handleCloseEditMenu();
      }
    }
  };

  async function handlePageChange(event: any, page: number): Promise<void> {
    page+=1;
    setPage(page);

    history.pushState(
      { page: page, limit: limit },
      '',
      `?page=${page}&limit=${limit}`
    );
    try {
      setIsLoading(true);
      await getProviderAxios(page, limit, cityId, areaId, subserviceId, search);
    } catch {
    } finally {
      setIsLoading(false);
    }
  }

  async function handleLimitChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): Promise<void> {
    try {
      setIsLoading(true);
      const limit = parseInt(event.target.value, 10);
      history.pushState(
        { page: page, limit: limit },
        '',
        `?page=${page}&limit=${limit}`
      );
      setLimit(limit);
    } catch {
    } finally {
    }
  }
  async function updateCities() {
    const cities = await GetCities();
    setCities(cities);
  }
  async function updateAreas() {
    const areas = await GetAreas();
    setAreas(areas);
  }
  async function updateServices() {
    const services = await GetServices();
    setServices(services);
  }
  async function updateSubServices() {
    const subservices = await GetSubServices();
    setSubServices(subservices);
  }

  const filterAreas = async () => {
    const allAreas = await GetAreas();
    if (cityId) {
      const areasFiltered = allAreas.filter((area) => area.cityId == cityId);
      setAreasFiltered(areasFiltered);
    } else {
      setAreasFiltered(allAreas);
    }
  };

  const filterSubServices = async () => {
    // if (!serviceId) {
    //   setSubservicesFiltered([]);
    //   return;
    // }
    const allSubservices = await GetSubServices();
    if (serviceId) {
      const subservicesFiltered = allSubservices.filter(
        (sub) => sub.serviceId == serviceId
      );
      setSubservicesFiltered(subservicesFiltered);
    } else {
      setSubservicesFiltered(allSubservices)
    }
  };
  return (
    <>
      <Card
        sx={{ backgroundColor: '#ffffff', border: '0', marginBottom: '5px' }}
      >
        <CardHeader
          action={
            <Box style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <div className="row"></div>
              <Button
                sx={{ mt: { xs: 2, md: 0, lg: 1.1 }, ml: 3, height: '50px' }}
                variant="contained"
                startIcon={<AddTwoToneIcon fontSize="small" />}
                onClick={() => handleOpenAddProvider()}
              >
                Add Providers
              </Button>
            </Box>
          }
          title="Providers Table"
        />

        <CardContent>
          <Grid container spacing={1}>
          <Grid item xs={6} md={3} lg={3}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-name-label">
                    Select City
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={cityId}
                    input={<OutlinedInput label="Select City" />}
                    onChange={(e, v) => handleChangeSelectCity(e, v)}
                  >
                    {Object.keys(fbCities).map((k) => {
                      return (
                        <MenuItem key={fbCities[k]?.id} value={fbCities[k]?.id}>
                          {' '}
                          {fbCities[k]?.name}{' '}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-name-label">
                    Select Area
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={areaId}
                    input={<OutlinedInput label="Select Area" />}
                    onChange={(e, v) => handleChangeSelectArea(e, v)}
                    endAdornment={<IconButton sx={{display: areaId? "": "none"}} onClick={() => setAreaId(null)}><ClearIcon/></IconButton>}
                  >
                    {Object.keys(areasFiltered).map((k) => {
                      return (
                        <MenuItem
                          key={areasFiltered[k]?.id}
                          value={areasFiltered[k]?.id}
                        >
                          {' '}
                          {areasFiltered[k]?.name}{' '}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-name-label">
                    Select Service
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={serviceId}
                    input={<OutlinedInput label="Select City" />}
                    onChange={(e, v) => handleChangeSelectService(e, v)}
                  >
                    {services.map((s) => {
                      return (
                        <MenuItem key={s?.id} value={s?.id}>
                          {' '}
                          {s?.name}{' '}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-name-label">
                    Select SubService
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={subserviceId}
                    input={<OutlinedInput label="Select Subservice" />}
                    onChange={(e, v) => handleChangeSelectSubserbive(e, v)}
                    endAdornment={<IconButton sx={{display: subserviceId? "": "none"}} onClick={() => {
                      setServiceId('');
                      setSubserviceId('');
                    }}><ClearIcon/></IconButton>}
                  >
                    {Object.keys(subServicesFiltered).map((k) => {
                      return (
                        <MenuItem
                          key={subServicesFiltered[k]?.id}
                          value={subServicesFiltered[k]?.id}
                        >
                          {' '}
                          {subServicesFiltered[k]?.service_name}{' '}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card
        sx={{ backgroundColor: '#ffffff', border: '0', marginBottom: '5px' }}
      >
        <form onSubmit={submitSearch}>
          <TextField
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder="Search..."
            size="medium"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              )
            }}
          />
        </form>
      </Card>
      <Card>
        <CardHeader title="Provider Table" />
        <Divider />
        <Dialog open={openAddProvider} onClose={hanldeCloseAddProvider}>
          <DialogContent>
            <AddNewUserComponent
              handleClose={hanldeCloseAddProvider}
              makeProvider={true}
              users={null}
            />
          </DialogContent>
        </Dialog>

        <Dialog open={openProfileImage} onClose={handleCloseProfileImage}>
          <DialogContent>
            <EditProfile
              handleClose={handleCloseProfileImage}
              userProvider={currentProvider}
              closeEditMenu={handleCloseEditMenu}
            />
          </DialogContent>
        </Dialog>

        <Dialog open={openContactInfo} onClose={handleCloseContactInfo}>
          <DialogContent>
            <EditContactInfo
              handleClose={handleCloseContactInfo}
              userProvider={currentProvider}
              closeEditMenu={handleCloseEditMenu}
            />
          </DialogContent>
        </Dialog>

        <Dialog open={openSubservice} onClose={handleCloseSubservice}>
          <DialogContent>
            <AddSubservice
              handleClose={handleCloseSubservice}
              providerSubServices={providerSubService}
              id={id}
            />
          </DialogContent>
        </Dialog>

        <Dialog open={openPackage} onClose={handleClosePackage}>
          <DialogContent>
            <AddPackage
              handleClose={handleClosePackage}
              endSub={endSub}
              id={id}
              closeEditMenu={handleCloseEditMenu}
            />
          </DialogContent>
        </Dialog>

        <Dialog open={openVerify} onClose={handleCloseVerify}>
          <DialogContent>
            <Verify
              hanldeClose={handleCloseVerify}
              id={currentUser?.uid}
              closeEditMenu={handleCloseEditMenu}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          open={openCancelVerify}
          onClose={handleColseCancelVerify}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {isloading ? (
            <SuspenseLoader />
          ) : (
            <>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to cancel verify?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleColseCancelVerify}>Cancel</Button>
                <Button onClick={handleCancelVerify} autoFocus>
                  Cancel Verify
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>

        <Dialog
          open={openCancelMembership}
          onClose={handleCloseCancelMembership}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {isloading ? (
            <SuspenseLoader />
          ) : (
            <>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to cancel verify?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseCancelMembership}>Cancel</Button>
                <Button onClick={handleCancelMembership} autoFocus>
                  Cancel Membership
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>

        {currentUser && (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openEditMenu}
            onClose={handleCloseEditMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={handleEditPrfileImage}>
              Edit Picture profile
            </MenuItem>
            <MenuItem onClick={handleOpenContactInfo}>
              Edit Contact Info
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClickSubservice();
                setProviderSubService(currentUser?.providedServices);
                setId(currentUser?.uid);
              }}
            >
              Edit Provided Services
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClickPackage();
                setEndSub(currentUser?.subscriptionEnd);
                setId(currentUser?.uid);
              }}
            >
              Add Packages
            </MenuItem>
            {!currentUser.verification && (
              <MenuItem onClick={handleOpenVerify}>
                <Typography sx={{ color: 'primary.main' }}>
                  Verify Provider
                </Typography>
              </MenuItem>
            )}
            {!!currentUser.verification && (
              <MenuItem onClick={handleOpenCancelVerify}>
                <Typography sx={{ color: 'error.main' }}>
                  UnVerify Provider
                </Typography>
              </MenuItem>
            )}
            <Divider />
            <MenuItem onClick={handleOpenCancelMembership}>
              <Typography sx={{ color: 'error.main' }}>
                Cancel Membership
              </Typography>
            </MenuItem>
          </Menu>
        )}

        {isloading ? (
          <SuspenseLoader />
        ) : (
          <Dialog
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDelete}>Cancel</Button>
              <Button>
                {' '}
                {/* onClick={handleDeleteItem} autoFocus */}
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>image</TableCell>
                <TableCell size="small">Id</TableCell>
                <TableCell size="small">Name</TableCell>
                <TableCell size="small">Phone</TableCell>
                <TableCell size="small">Sub Service</TableCell>
                <TableCell size="small">City</TableCell>
                <TableCell size="small">Subscription End Date</TableCell>
                <TableCell size="small" align="center">
                  Edit
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Providers?.users?.map((user) => {
                const currentUser = user;
                return (
                  <>
                    <TableRow key={currentUser.id}>
                      <TableCell>
                        <img
                          src={
                            currentUser.photoURL
                              ? currentUser.photoURL
                              : '/static/images/avatars/6.png'
                          }
                          alt="provider Image"
                          height={64}
                          width={64}
                        />
                        {currentUser.verification && (
                          <VerifiedIcon
                            color="primary"
                            sx={{ position: 'static', marginLeft: '-30px' }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {currentUser.uid}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          <Link to={`/provider/${currentUser.uid}`}>
                            {currentUser.displayName}
                          </Link>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {currentUser.phoneNumber}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {currentUser.providedServices.map(s=>{
                            return fbSubServices[s.replace('sub_services/','')]?.service_name;
                          }).join(' - ')}
                          
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {(fbCities.find(
                            (city) => city?.path == currentUser?.city
                          )?.name ?? 'not set') +
                            ', ' +
                            (fbAreas.find(
                              (area) => area?.path == currentUser?.area
                            )?.name ?? 'not set')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          { currentUser?.subscriptionEnd ? new Date(currentUser.subscriptionEnd)?.toDateString() : "No Subscription"}
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        <Button
                          color="info"
                          id="basic-button"
                          aria-controls={
                            openEditMenu ? 'basic-menu' : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={openEditMenu ? 'true' : undefined}
                          onClick={(e) => handleOpenEditMenu(e, currentUser)}
                        >
                          <EditIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component="div"
            count={resultsCount} //TODO: Get total from search engine
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page-1}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 30]}
          />
        </Box>
      </Card>
    </>
  );
}

export default DataTable;
