import { getFirestore, collection, onSnapshot, getDocs } from 'firebase/firestore';
import { CityConverter } from 'src/firebase/firestore/convertors/city.converer';
import { app } from '../../../firebase';
import { City } from 'src/firebase/firestore/city.type';

const firestore = getFirestore(app);
const citiesCollection = collection(firestore, 'cities').withConverter(
  new CityConverter()
);

export async function GetCities(): Promise<City[]> {


  if(!sessionStorage.getItem('Cities')){
    const cities = (await getDocs<City>(citiesCollection)).docs.map(c=>c.data());
    sessionStorage.setItem('Cities',JSON.stringify(cities))
    return cities;
  }
  const cacheCities: City[] = JSON.parse( sessionStorage.getItem('Cities'));
  return cacheCities;
}
