import {
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SetOptions,
  SnapshotOptions,
  WithFieldValue
} from 'firebase/firestore';
import { SubService } from '../subService.type';

export class SubServiceConverter implements FirestoreDataConverter<SubService> {
  toFirestore(modelObject: WithFieldValue<SubService>): DocumentData;
  toFirestore(
    modelObject: PartialWithFieldValue<SubService>,
    options: SetOptions
  ): DocumentData;
  toFirestore(
    modelObject: SubService,
    options?: unknown
  ): import('@firebase/firestore').DocumentData {
    delete modelObject.serviceId;
    return { ...modelObject };
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions
  ): SubService {
    const data = snapshot.data();
    const subService: SubService = {
      id: snapshot.id,
      service_name: data.service_name,
      service_description: data.service_description,
      image: data.image,
      service_parent: data.service_parent,
      serviceId: data.service_parent?.id
    };

    return subService;
  }
}
