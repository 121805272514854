import {
    Card,
    Grid,
    Box,
    CardContent,
    Typography,
    Avatar,
    alpha,
    styled,
  } from '@mui/material';
  import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { API_URL } from 'src/constants';


  const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
        margin: ${theme.spacing(2, 0, 1, -0.5)};
        display: flex;
        align-items: center;
        justify-content: center;
        padding: ${theme.spacing(0)};
        margin-bottom: ${theme.spacing(2)};
        border-radius: 60px;
        height: ${theme.spacing(8.5)};
        width: ${theme.spacing(8.5)};
        background: ${
          theme.palette.mode === 'dark'
            ? theme.colors.alpha.trueWhite[30]
            : alpha(theme.colors.alpha.black[100], 0.07)
        };
      
        img {
          background: ${theme.colors.alpha.trueWhite[100]};
          padding: ${theme.spacing(0.5)};
          display: block;
          border-radius: inherit;
          height: ${theme.spacing(7.5)};
          width: ${theme.spacing(7.5)};
        }
    `
  );

function Cards() {
  const [activeProviders, setActiveProviders] = useState(0);
  const [allUsers, setAllUsers] = useState(0);
  const [expiredProviders, setExpiredProviders] = useState(0);
  const [newProviders, setNewProviders] = useState(0);
  useEffect(() => {
    const  getData = () => {
      axios.get(`${API_URL}/stats`).then((res) => {
        setActiveProviders(res.data.activeProviders);
        setAllUsers(res.data.allUsers)
        setExpiredProviders(res.data.expiredProviders)
        setNewProviders(res.data.newProviders)
      })
    }
    getData();
  }, [])
  return(
      <Grid container spacing={3}>
        <Grid item textAlign="center" xs={12} md={3} >
            <Card
              sx={{
                px: 1,
                py: 1
              }}
            >
              <CardContent sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Box >
                  {/* <AvatarWrapper>
                      <img
                      alt="BTC"
                      src="/static/images/placeholders/logo/device77.jpg"
                      />
                  </AvatarWrapper> */}
                </Box>
                <Box >
                  <Typography variant="h3" noWrap>
                      All Users
                  </Typography>
                  <Box
                      sx={{
                      pt: 1
                      }}
                  >
                      <Typography variant="h3" gutterBottom noWrap>
                      {allUsers}
                      </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid textAlign="center" xs={12} md={3} item>
            <Card
              sx={{
                px: 1,
                py: 1
              }}
            >
              <CardContent sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Box>
                {/* <AvatarWrapper>
                  <img
                    alt="cities"
                    src="/static/images/placeholders/logo/city.png"
                  />
                </AvatarWrapper> */}
                </Box>
                <Box>
                  <Typography variant="h3" noWrap>
                    Active Providers
                  </Typography>
                  <Box
                      sx={{
                      pt: 1
                      }}
                  >
                      <Typography variant="h3" gutterBottom noWrap>
                      {activeProviders}
                      </Typography>
                  </Box>

                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid textAlign="center" xs={12} md={3} item>
            <Card
              sx={{
                px: 1,
                py: 1
              }}
            >
              <CardContent sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Box>
                  {/* <AvatarWrapper>
                      <img
                      alt="cities"
                      src="/static/images/placeholders/logo/zone1.png"
                      />
                  </AvatarWrapper> */}
                </Box>
                <Box>
                  <Typography variant="h3" noWrap>
                    Expired Providers
                  </Typography>
                  <Box
                      sx={{
                      pt: 1
                      }}
                  >
                      <Typography variant="h3" gutterBottom noWrap>
                      {expiredProviders}
                      </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid textAlign="center" xs={12} md={3} item>
            <Card
              sx={{
                px: 1,
                py: 1
              }}
            >
              <CardContent sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Box>
                  {/* <AvatarWrapper>
                      <img
                      alt="cities"
                      src="/static/images/placeholders/logo/zone1.png"
                      />
                  </AvatarWrapper> */}
                </Box>
                <Box>
                  <Typography variant="h3" noWrap>
                    New Providers
                  </Typography>
                  <Box
                      sx={{
                      pt: 1
                      }}
                  >
                      <Typography variant="h3" gutterBottom noWrap>
                      {newProviders}
                      </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
      </Grid>
  )
}

export default Cards;