import { app } from '../../../../firebase';
import { collection, doc, getDoc, getFirestore, onSnapshot, query, where } from "firebase/firestore";
import { UsersConverter } from "src/firebase/firestore/convertors/users.convertor";
import { PostsConverter } from 'src/firebase/firestore/convertors/posts.converter';
import { ReviewsConverter } from 'src/firebase/firestore/convertors/reviews.converter';
import { CityConverter } from 'src/firebase/firestore/convertors/city.converer';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { 
    Card, 
    Grid, 
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow 
} from '@mui/material';
import { Box } from '@mui/system';
import styled from '@emotion/styled';

const firestore = getFirestore(app);
const usersCollection = collection(firestore, 'users').withConverter(
  new UsersConverter()
);
const postsCollection = collection(firestore, 'posts').withConverter(
new PostsConverter()
);
const reviewsCollection = collection(firestore, 'reviews').withConverter(
    new ReviewsConverter()
  );
const citiesCollection = collection(firestore,'cities').withConverter(new CityConverter());;

const Boxi = styled(Box)(`
    img {
        border-radius: 50%;
        height: 256px;
        width: 256px;
    }
`
);

function Profile () {
    const params = useParams();
    const [idParam, setIDParam] = useState(params.id);
    const [fbUsers, setFbUsers] = useState<any>();
    const [fbCities, setCities ] = useState({});
    const [fbPosts, setFbPosts] = useState([]);
    const [fbReviews, setFbReviews] = useState([]);
    const [isloading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        getUser();
        getCities();
        getPosts();
        getReviews();
        // getAllUsers();
    }, [])
    const getUser = async (): Promise<any> => {
        const userQuery = await getDoc(doc(usersCollection,idParam));
        setFbUsers(userQuery.data());
    }
    const getCities = async (): Promise<any>=>{
        onSnapshot(citiesCollection,async (d) => {
            const cities = {};
             d.docs.forEach(d=>{
                cities[d.id] = d.data();
            });
            setCities(cities); 
        });
     }
    const getPosts = async (): Promise<any> => {
        let q = query(postsCollection, where('user', '==', doc(usersCollection, idParam)));
        onSnapshot(q,(snapShot) => {
            setFbPosts(snapShot.docs.map((d) => d.data()));
        });
    };
    const getReviews = async () : Promise<any> => {
        let q = query(reviewsCollection, where("reviewed_id", '==', doc(usersCollection, idParam)));
        onSnapshot(q, (snap) => {
            setFbReviews(snap.docs.map(d => d.data()));
        })
    }
    const getAllUsers = async (): Promise<any>=>{
        onSnapshot(usersCollection,async (d) => {
            const allUsers = {};
             d.docs.forEach(d=>{
                allUsers[d.id] = d.data();
            });
            //setFbAllUsers(allUsers); 
        });
     }
    
    const city =  fbUsers?.city;
    const date = fbUsers?.created_time.seconds;
    return(
        
        <>
            <Card>
                <Grid spacing={0} container>
                    
                    <Grid item xs={6}>
                        <Box py={3}>
                            <Typography
                                sx={{
                                mt: 2,
                                mb: 5,
                                px: 2
                                }}
                                variant="h4"
                            >
                                {fbUsers?.displayName}
                            </Typography>
                            <Typography
                                sx={{
                                mt: 2,
                                mb: 5,
                                px: 2
                                }}
                                variant="h4"
                            >
                                {fbUsers?.phone}
                            </Typography>
                            <Typography
                                sx={{
                                mt: 2,
                                mb: 5,
                                px: 2
                                }}
                                variant="h4"
                            >
                                {new Intl.DateTimeFormat('en-US', {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    }).format(date && date*1000)
                                }
                            </Typography>
                            <Typography
                                sx={{
                                mt: 2,
                                mb: 5,
                                px: 2
                                }}
                                variant="h4"
                            >
                                {fbCities[city?.id]?.name}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Boxi sx={{
                            display: 'flex',
                            justifyContent: 'end',
                            padding: '25px'
                        }}>
                            <img src={fbUsers?.photo_url ? fbUsers?.photo_url : '/static/images/avatars/6.png'} 
                            alt="provider Image"
                            />
                        </Boxi>
                    </Grid>
                </Grid>
            </Card>
            <Card sx={{marginTop: '15px', padding: '10px'}}>
                <Grid>
                    <h1>Posts</h1>
                    <TableContainer>
                        <Table >
                            <TableHead >
                            <TableRow>
                                <TableCell size='small'>Created Post</TableCell>
                                <TableCell>Content</TableCell>
                                <TableCell>Images</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {fbPosts.map((post) => {
                                return (
                                <TableRow key={post.id}>
                                    
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                            fontSize={12}
                                        >
                                            {new Intl.DateTimeFormat('en-US', {
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric'
                                            }).format((post.created_on.seconds)*1000)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            width='70vh'
                                            sx={{wordBreak: 'break-word'}}
                                        >
                                            {post.content}
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        {                                         
                                          post.images.map((object, i)=>{
                                            return <span style={{marginLeft: '5px'}} key={i} ><img alt='post images' src={object} key={i} height={64} width={64}/></span>
                                            })
                                        }
                                    </TableCell>
                                </TableRow>
                                );
                            })}
                            </TableBody>
                        </Table>
                    </TableContainer> 
                </Grid>
            </Card>
            <Card sx={{marginTop: '15px', padding: '10px'}}>
                <Grid>
                    <h1>Reviews</h1>
                    <TableContainer>
                        <Table>
                            <TableHead>
                            <TableRow>
                                <TableCell size='small'>reviewerId</TableCell>
                                <TableCell size='small'>Rating</TableCell>
                                <TableCell size='small'>message</TableCell>
                                <TableCell size='small'>date</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {fbReviews.map((review) => {                   
                                return (
                                <TableRow key={review.id}>
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {review.rating}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {review.message}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                    <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                    >
                                        
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                                );
                            })}
                            </TableBody>
                        </Table>
                    </TableContainer> 
                </Grid>
            </Card>
        </>
    )
}

export default Profile;